import { create } from "zustand";
import { apiClient } from "../utils/api";
// import { useNavigate } from "react-router-dom";
import { useAuthStore } from "./authStore";
import Venue from "../models/Venue";

interface VenueStore {
  addBooking: any;
  monthlyBookings: any;
  venues: Venue[];
  loading: boolean;
  error: string | null;
  fetchVenues: () => Promise<void>;
  addVenue: (data: Partial<Venue>) => Promise<void>;
  deleteVenue: (id: number) => Promise<void>;
  updateVenue: (id: number, data: Partial<Venue>) => Promise<Venue>;
  openMonthlyBooking: (id: number, month: string) => Promise<void>;
  fetchMonthlyBookings: (id: number) => Promise<void>;
}

export const useVenueStore = create<VenueStore>((set) => ({
  venues: [],
  loading: false,
  error: null,
  monthlyBookings: [],
  addBooking: async () => {}, // Added missing addBooking property

  fetchVenues: async () => {
    try {
      set({ loading: true, error: null });
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("請先登入");
      }

      const response = await apiClient.get("/venue/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response.data", response.data);

      set({
        venues: response.data?.map((t: any) => {
          t.image = t.photos?.[0] || "";
          t.images = t.photos || [];
          return t;
        }),
        loading: false,
      });
    } catch (error: any) {
      set({
        error: error.response?.data?.message || "獲取場地列表失敗",
        loading: false,
      });
      throw error;
    }
  },

  addVenue: async (data: Partial<Venue>) => {
    try {
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("未登入，請先登入");
      }

      const processedData = {
        ...data,
        totalSpots: Number(data.totalSpots) || 0,
        availableSpots: Number(data.totalSpots) || 0,
        dailyFee: Number(data.dailyFee) || 0,
        bookingOpenDays: Number(data.bookingOpenDays) || 1,
        minBookingDays: Number(data.minBookingDays) || 1,
        maxDaysPerVendor: Number(data.maxDaysPerVendor) || 7,
        maxBookingDays: Number(data.maxBookingDays) || 30,
      };

      const response = await apiClient.post("/venue", processedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      set((state) => ({
        venues: [...state.venues, response.data],
      }));

      return response.data;
    } catch (error: any) {
      console.error("新增場地失敗:", error);
      throw new Error(
        error.response?.data?.message || "新增場地失敗，請稍後再試"
      );
    }
  },

  deleteVenue: async (id: number) => {
    try {
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("未登入，請先登入");
      }

      await apiClient.delete(`/venue/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      set((state) => ({
        venues: state.venues.filter((venue) => venue.id !== id),
      }));

      // return true;
    } catch (error) {
      console.error("刪除場地失敗:", error);
      throw error;
    }
  },

  updateVenue: async (id: number, data: Partial<Venue>) => {
    try {
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("未登入，請先登入");
      }

      const processedData = {
        ...data,
        id: id,
        dailyFee: data.dailyFee !== undefined ? Number(data.dailyFee) : 0,
        minBookingDays:
          data.minBookingDays !== undefined ? Number(data.minBookingDays) : 1,
        maxDaysPerVendor:
          data.maxDaysPerVendor !== undefined
            ? Number(data.maxDaysPerVendor)
            : 7,
        bookingOpenDays:
          data.bookingOpenDays !== undefined ? Number(data.bookingOpenDays) : 1,
        totalSpots: data.totalSpots !== undefined ? Number(data.totalSpots) : 0,
        availableSpots:
          data.availableSpots !== undefined ? Number(data.availableSpots) : 0,
      };

      console.log("準備更新場地資料:", processedData);

      const response = await apiClient.put(`/venue/${id}/info`, processedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      set((state) => ({
        venues: state.venues.map((venue) =>
          venue.id === id ? { ...venue, ...response.data } : venue
        ),
      }));

      return response.data;
    } catch (error: any) {
      console.error("更新場地失敗:", error);
      throw new Error(
        error.response?.data?.message || "更新場地失敗，請稍後再試"
      );
    }
  },

  openMonthlyBooking: async (id: number, month: string) => {
    try {
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("未登入，請先登入");
      }

      const response = await apiClient.post(
        `/venue/monthlybooking/open`,
        {
          month,
          venueId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.error("開啟預訂失敗:", error);
      throw new Error(
        error.response?.data?.message || "開啟預訂失敗，請稍後再試"
      );
    }
  },
  fetchMonthlyBookings: async (id: Number) => {
    try {
      set({ loading: true, error: null });
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("請先登入");
      }
      const response = await apiClient.get(`/venue/monthlybookings?venueId=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response.data", response.data);

      set({
        monthlyBookings: response.data,
        loading: false,
      });
    } catch (error: any) {
      set({
        error: error.response?.data?.message || "獲取場地列表失敗",
        loading: false,
      });
      throw error;
    }
  },
}));

export default useVenueStore;
