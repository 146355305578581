import React, { useState } from 'react';
import { Plus, Edit2, Trash2, Image, Clock } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';
import MenuForm from './MenuForm';

const MenuManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [editItem, setEditItem] = useState<any>(null);
  const items = useVendorStore((state) => state.menuItems);
  const toggleItemAvailability = useVendorStore((state) => state.toggleItemAvailability);
  const deleteMenuItem = useVendorStore((state) => state.deleteMenuItem);

  const handleEdit = (item: any) => {
    setEditItem(item);
    setShowForm(true);
  };

  const handleDelete = (id: number) => {
    if (window.confirm('確定要刪除此品項嗎？')) {
      deleteMenuItem(id);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditItem(null);
  };

  const categories = ['主食', '小菜', '飲料', '甜點'];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">菜單管理</h2>
        <button
          onClick={() => setShowForm(true)}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 flex items-center"
        >
          <Plus className="w-5 h-5 mr-2" />
          新增品項
        </button>
      </div>

      {categories.map((category) => {
        const categoryItems = items.filter((item) => item.category === category);
        if (categoryItems.length === 0) return null;

        return (
          <div key={category} className="mb-8">
            <h3 className="text-lg font-semibold mb-4">{category}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {categoryItems.map((item) => (
                <div
                  key={item.id}
                  className="bg-white rounded-lg shadow p-4 flex space-x-4"
                >
                  <div className="w-32 h-32 bg-gray-200 rounded-lg overflow-hidden">
                    {item.image ? (
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <Image className="w-8 h-8 text-gray-400" />
                      </div>
                    )}
                  </div>

                  <div className="flex-1">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-semibold text-lg">{item.name}</h3>
                        <p className="text-sm text-gray-500 mt-1">
                          {item.description}
                        </p>
                      </div>
                      <span className="font-semibold text-orange-600">
                        NT${item.price}
                      </span>
                    </div>

                    <div className="mt-4 flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <span className="px-2 py-1 bg-gray-100 text-gray-600 rounded text-sm">
                          {item.category}
                        </span>
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={item.available}
                            onChange={() => toggleItemAvailability(item.id)}
                            className="form-checkbox h-4 w-4 text-orange-600"
                          />
                          <span className="ml-2 text-sm text-gray-600">
                            供應中
                          </span>
                        </label>
                      </div>

                      {item.preOrder && (
                        <div className="flex items-center text-sm text-gray-600">
                          <Clock className="w-4 h-4 mr-1" />
                          <span>預訂截止: {item.preOrderDeadlineTime}</span>
                        </div>
                      )}

                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEdit(item)}
                          className="p-2 text-gray-400 hover:text-gray-600"
                        >
                          <Edit2 className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(item.id)}
                          className="p-2 text-gray-400 hover:text-red-600"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {showForm && <MenuForm onClose={handleCloseForm} editItem={editItem} />}
    </div>
  );
};

export default MenuManager;