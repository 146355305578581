import { MapPin, Calendar } from 'lucide-react';
import useVenueStore from '../../stores/venueStore';

interface ActiveVendor {
  id: string;
  name: string;
  image: string;
  startDate: string;
  endDate: string;
  timeSlot: string;
  spotNumber: string;
}

interface VenueOperation {
  id: number;
  name: string;
  image: string;
  address: string;
  totalSpots: number;
  activeVendors: ActiveVendor[];
}

const OperationsManager = () => {
  // 這裡應該從 store 獲取實際數據
  const venues = useVenueStore((state) => state.venues);
  
  const activeVenues: VenueOperation[] = venues.map(venue => ({
    ...venue,
    activeVendors: [
      {
        id: 'vendor-1',
        name: '美食餐車',
        image: 'https://example.com/truck1.jpg',
        startDate: '2024-03-20',
        endDate: '2024-03-25',
        timeSlot: '上午',
        spotNumber: 'A1'
      },
      // 模擬數據
    ]
  }));

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">營運管理</h2>
      <div className="grid grid-cols-1 gap-6">
        {activeVenues.map(venue => (
          <div key={venue.id} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-start space-x-4 mb-6">
              <img
                src={venue.image}
                alt={venue.name}
                className="w-24 h-24 rounded-lg object-cover"
              />
              <div>
                <h3 className="text-lg font-medium">{venue.name}</h3>
                <p className="text-gray-500 text-sm mt-1 flex items-center">
                  <MapPin className="w-4 h-4 mr-1" />
                  {venue.address}
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4 className="font-medium flex items-center">
                <Calendar className="w-4 h-4 mr-2" />
                目前營運中的餐車
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {venue.activeVendors.map(vendor => (
                  <div key={vendor.id} className="border rounded-lg p-4">
                    <div className="flex items-center space-x-3">
                      <img
                        src={vendor.image}
                        alt={vendor.name}
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div>
                        <h5 className="font-medium">{vendor.name}</h5>
                        <p className="text-sm text-gray-500">
                          攤位 {vendor.spotNumber} | {vendor.timeSlot}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3 text-sm text-gray-600">
                      {vendor.startDate} ~ {vendor.endDate}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperationsManager; 