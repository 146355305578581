import React, { useState, useEffect } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Save, Plus, Settings, Download, X } from "lucide-react";
import useLayoutStore from "../../stores/layoutStore";
import DraggableSpot from "./DraggableSpot";
import html2canvas from "html2canvas";

interface VenueLayoutEditorProps {
  venue: any;
  setSettings: React.Dispatch<React.SetStateAction<any>>;
}

interface Spot {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
  styleId: string;
  notes?: string;
  customLabel?: string;
  venueId: string;
  rotation?: number;
}

interface LayoutAreaProps {
  spots: Spot[];
  styles: Record<
    string,
    {
      backgroundColor: string;
      borderColor: string;
      label: string;
    }
  >;
  setSelectedSpot: (id: string) => void;
  updateSpot: (id: string, updates: Partial<Spot>) => void;
  gridSize: number;
  snapToGrid: boolean;
  removeSpot: (id: string) => void;
}

const GridBackground = ({
  gridSize,
  show,
}: {
  gridSize: number;
  show: boolean;
}) => {
  if (!show) return null;

  return (
    <div
      className="absolute inset-0 pointer-events-none"
      style={{
        backgroundImage: `
          linear-gradient(to right, #e5e7eb 1px, transparent 1px),
          linear-gradient(to bottom, #e5e7eb 1px, transparent 1px)
        `,
        backgroundSize: `${gridSize}px ${gridSize}px`,
      }}
    />
  );
};

const LayoutArea = ({
  spots,
  styles,
  setSelectedSpot,
  updateSpot,
  gridSize,
  snapToGrid,
  removeSpot,
}: LayoutAreaProps) => {
  const [, drop] = useDrop(
    () => ({
      accept: "spot",
      hover: (item: any, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();
        if (!delta) return;

        const left = Math.round(item.initialLeft + delta.x);
        const top = Math.round(item.initialTop + delta.y);

        if (snapToGrid) {
          const newLeft = Math.round(left / gridSize) * gridSize;
          const newTop = Math.round(top / gridSize) * gridSize;
          updateSpot(item.id, { left: newLeft, top: newTop });
        } else {
          updateSpot(item.id, { left, top });
        }
      },
    }),
    [updateSpot, snapToGrid, gridSize]
  );

  // 計算實際攤位編號（不包括萬用標示）
  const getSpotNumber = (currentIndex: number) => {
    return spots
      .slice(0, currentIndex + 1)
      .filter((spot: { styleId: string }) => spot.styleId !== "custom").length;
  };

  return (
    <div
      ref={drop}
      className="bg-gray-100 rounded-lg h-[600px] relative overflow-hidden"
    >
      <GridBackground gridSize={gridSize} show={snapToGrid} />
      {spots.map(
        (
          spot: {
            id: any;
            left?: number;
            top?: number;
            width?: number;
            height?: number;
            styleId?: string;
            notes?: string | undefined;
            customLabel?: string | undefined;
            venueId?: string;
          },
          index: number
        ) => (
          <DraggableSpot
            key={spot.id}
            spot={{
              id: spot.id,
              left: spot.left || 0,
              top: spot.top || 0,
              width: spot.width || 0,
              height: spot.height || 0,
              styleId: spot.styleId || "",
              notes: spot.notes,
              customLabel: spot.customLabel,
              venueId: spot.venueId || "",
            }}
            styles={styles}
            onSelect={setSelectedSpot}
            spotNumber={getSpotNumber(index)}
            updateSpot={updateSpot}
            removeSpot={removeSpot}
          />
        )
      )}
    </div>
  );
};

const VenueLayoutEditor: React.FC<VenueLayoutEditorProps> = ({
  venue,
  setSettings,
}) => {
  const metersToPixels = (meters: number) => meters * 20;
  const [selectedStyle, setSelectedStyle] = useState("default");
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const {
    spots,
    styles,
    addSpot,
    updateSpot,
    removeSpot,
    setSelectedSpot,
    saveLayout,
    snapToGrid,
    setSnapToGrid,
    gridSize,
    // setGridSize,
    getSpotsByVenueId,
    fetchSpots,
  } = useLayoutStore();

  const venueSpots = getSpotsByVenueId(venue.id.toString());
  const handleAddSpot = () => {
    addSpot({
      id: `spot-${Date.now()}`,
      left: 50,
      top: 50,
      width: metersToPixels(3),
      height: metersToPixels(6),
      styleId: selectedStyle,
      venueId: venue.id.toString(),
      rotation: 0, // Add missing rotation property
      notes:'',
      customLabel:''
    });
  };

  const handleExportImage = () => {
    const layoutArea = document.querySelector(".layout-area") as HTMLElement;
    if (!layoutArea) return;

    html2canvas(layoutArea, {
      backgroundColor: "#f3f4f6", // 與原背景色相同
      scale: 2, // 提高輸出質量
    }).then((canvas) => {
      const image = canvas.toDataURL("image/jpeg", 0.9);
      setPreviewImage(image);
    });
  };

  const handleExportToSettings = () => {
    try {
      setSettings((prev: any) => ({
        ...prev,
        layoutImage: previewImage,
      }));
      setPreviewImage(null);
      alert("成功導出到營業設定！");
    } catch (error) {
      console.error("導出失敗:", error);
      alert("導出失敗，請稍後再試");
    }
  };
  useEffect(() => {
    fetchSpots(venue.id.toString());
  }, [fetchSpots]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-4">
        {/* 工具列 */}
        <div className="flex justify-between items-center">
          <div className="space-x-2">
            <button
              onClick={handleAddSpot}
              className="px-3 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 flex items-center space-x-1"
            >
              <Plus className="w-4 h-4" />
              <span>新增攤位</span>
            </button>

            <select
              value={selectedStyle}
              onChange={(e) => setSelectedStyle(e.target.value)}
              className="px-3 py-2 border rounded-lg"
            >
              {Object.entries(styles).map(([id, style]) => (
                <option key={id} value={id}>
                  {style.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={snapToGrid}
                onChange={(e) => setSnapToGrid(e.target.checked)}
              />
              <span>對齊網格</span>
            </label>

            <button
              onClick={() => saveLayout(venue.id.toString())}
              className="px-3 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center space-x-1"
            >
              <Save className="w-4 h-4" />
              <span>儲存配置</span>
            </button>

            <button
              onClick={handleExportImage}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center space-x-1"
            >
              <Download className="w-4 h-4" />
              <span>預覽平面圖</span>
            </button>
          </div>
        </div>

        {/* 場地配置區域 */}
        <div className="layout-area">
          <LayoutArea
            spots={venueSpots}
            styles={styles}
            setSelectedSpot={setSelectedSpot}
            updateSpot={updateSpot}
            gridSize={gridSize}
            snapToGrid={snapToGrid}
            removeSpot={removeSpot}
          />
        </div>

        {/* 預覽對話框 */}
        {previewImage && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg max-w-3xl w-full mx-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">場地平面圖預覽</h3>
                <button
                  onClick={() => setPreviewImage(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              <img src={previewImage} alt="場地平面圖" className="w-full" />
              <div className="mt-4 flex justify-end space-x-2">
                <button
                  onClick={handleExportToSettings}
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 flex items-center space-x-1"
                >
                  <Settings className="w-4 h-4 mr-1" />
                  <span>導出到營業設定</span>
                </button>
                <button
                  onClick={() => {
                    const link = document.createElement("a");
                    link.download = `${venue.name}-平面圖.jpg`;
                    link.href = previewImage;
                    link.click();
                  }}
                  className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                >
                  下載圖片
                </button>
                <button
                  onClick={() => setPreviewImage(null)}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
                >
                  關閉
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default VenueLayoutEditor;
