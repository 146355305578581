import React, { useState } from 'react';
import { Calendar, TrendingUp, MapPin, Menu as MenuIcon, Bell, User, X, LayoutDashboard, LogOut } from 'lucide-react';
import VenueList from '../components/venue/VenueList';
import BookingManager from '../components/venue/BookingManager';
import VenueAnalytics from '../components/venue/VenueAnalytics';
import VenueSettings from '../components/venue/VenueSettings';
import useVenueStore from '../stores/venueStore';
import VenueBookingCard from '../components/venue/VenueBookingCard';
import ApplicationList from '../components/venue/ApplicationList';
import OperationsManager from '../components/venue/OperationsManager';
import useAuthStore from '../stores/authStore';
import { useNavigate } from 'react-router-dom';

const VenueDashboard = () => {
  const [activeTab, setActiveTab] = useState('venues');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const venues = useVenueStore((state) => state.venues);
  const currentVenue = venues[0]; // 目前使用第一個場地的資料，之後可以改為使用登入者的場地
  const [selectedVenue, setSelectedVenue] = useState<string | null>(null);
  const [showApplications, setShowApplications] = useState(false);
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);

  const venueInfo = {
    name: currentVenue?.name || '信義商圈場地管理',
    avatar: currentVenue?.image || 'https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?auto=format&fit=crop&q=80',
    notifications: 2,
  };

  const menuItems = [
    { id: 'operations', label: '營運管理', icon: LayoutDashboard },
    { id: 'venues', label: '場地管理', icon: MapPin },
    { id: 'bookings', label: '預約管理', icon: Calendar },
    { id: 'analytics', label: '營運分析', icon: TrendingUp },
    { id: 'settings', label: '基本資料', icon: User },
  ];

  const handleViewApplications = (venueId: string) => {
    setSelectedVenue(venueId);
    setShowApplications(true);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'operations':
        return <OperationsManager />;
      case 'venues':
        return <VenueList />;
      case 'bookings':
        // return renderBookingManagement();
        return <BookingManager />;
      case 'analytics':
        return <VenueAnalytics />;
      case 'settings':
        return <VenueSettings venue={currentVenue} />;
      default:
        return <OperationsManager />;
    }
  };

  const renderBookingManagement = () => {
    return (
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">預約管理</h2>
        <div className="grid grid-cols-1 gap-4">
          {venues.map(venue => (
            <VenueBookingCard
              key={venue.id}
              venue={venue}
              onViewApplications={handleViewApplications}
            />
          ))}
        </div>
      </div>
    );
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* 側邊欄 */}
      <div className={`bg-white shadow-lg ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300`}>
        <div className="p-4">
          <div className="flex items-center space-x-4">
            <img
              src={venueInfo.avatar}
              alt={venueInfo.name}
              className="w-10 h-10 rounded-full object-cover"
            />
            {isSidebarOpen && (
              <div className="flex-1">
                <h2 className="font-semibold">{venueInfo.name}</h2>
                <p className="text-sm text-gray-500">場地管理者</p>
              </div>
            )}
          </div>
        </div>

        <nav className="mt-8">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => setActiveTab(item.id)}
              className={`w-full flex items-center px-4 py-3 ${
                activeTab === item.id
                  ? 'bg-orange-50 text-orange-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <item.icon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-4">{item.label}</span>}
            </button>
          ))}
        </nav>

        <div className="mt-auto border-t border-gray-200 p-4">
          <button
            onClick={handleLogout}
            className="flex items-center text-gray-700 hover:text-orange-600 w-full px-4 py-2 rounded-lg hover:bg-orange-50"
          >
            <LogOut className="w-5 h-5 mr-3" />
            登出
          </button>
        </div>
      </div>

      {/* 主要內容區 */}
      <div className="flex-1">
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-6 py-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="text-gray-500 hover:text-gray-700"
            >
              <MenuIcon className="w-6 h-6" />
            </button>

            <div className="flex items-center space-x-4">
              <button className="relative text-gray-500 hover:text-gray-700">
                <Bell className="w-6 h-6" />
                {venueInfo.notifications > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                    {venueInfo.notifications}
                  </span>
                )}
              </button>
            </div>
          </div>
        </header>

        <main className="p-6">
          {renderContent()}
        </main>
      </div>

      {showApplications && selectedVenue && (
        <ApplicationList
          venueId={selectedVenue}
          onClose={() => setShowApplications(false)}
        />
      )}
    </div>
  );
};

export default VenueDashboard;