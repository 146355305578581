import React, { useState } from "react";
import { X, Plus, Trash2, Image, Edit, Trash } from "lucide-react";
import useVenueStore from "../../stores/venueStore";
import { useAuthStore } from "../../stores/authStore";
import { apiClient } from "../../utils/api";

interface VenueFormProps {
  onClose: () => void;
}

interface TimeSlot {
  id: string;
  period: string;
  startTime: string;
  endTime: string;
}

const VenueForm: React.FC<VenueFormProps> = ({ onClose }) => {
  const addVenue = useVenueStore((state) => state.addVenue);
  const loading = useVenueStore((state) => state.loading);
  const [error, setError] = useState<string>("");

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    description: "",
    totalSpots: 0,
    availableSpots: 0,
    dailyFee: 0,
    bookingOpenDays: 1,
    minBookingDays: 1,
    maxDaysPerVendor: 7,
    maxBookingDays: 30,
    bookingOpenTime: "09:00",
    timeSlots: [] as TimeSlot[],
    facilities: [] as string[],
    operatingDays: [] as string[],
    images: [] as string[],
    owner: {
      name: "",
      phone: "",
      email: "",
      taxId: "",
      address: "",
      bankAccount: "",
      bankName: "",
      bankBranch: "",
    },
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      handleImageUpload(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const processedData = {
        ...formData,
        totalSpots: Number(formData.totalSpots),
        availableSpots: Number(formData.totalSpots),
        dailyFee: Number(formData.dailyFee),
        bookingOpenDays: Number(formData.bookingOpenDays),
        minBookingDays: Number(formData.minBookingDays),
        maxDaysPerVendor: Number(formData.maxDaysPerVendor),
        maxBookingDays: Number(formData.maxBookingDays),
      };

      await addVenue(processedData);
      onClose();
    } catch (error: any) {
      alert(error.message || "新增場地失敗");
    }
  };

  const addTimeSlot = () => {
    const newSlot = {
      id: Date.now().toString(),
      period: "custom",
      startTime: "00:00",
      endTime: "00:00",
    };
    setFormData({
      ...formData,
      timeSlots: [...formData.timeSlots, newSlot],
    });
  };

  const removeTimeSlot = (id: string) => {
    setFormData({
      ...formData,
      timeSlots: formData.timeSlots.filter((slot) => slot.id !== id),
    });
  };

  const weekDays = [
    { value: "monday", label: "週一" },
    { value: "tuesday", label: "週二" },
    { value: "wednesday", label: "週三" },
    { value: "thursday", label: "週四" },
    { value: "friday", label: "週五" },
    { value: "saturday", label: "週六" },
    { value: "sunday", label: "週日" },
  ];

  const formatTime = (time: string) => {
    return time.substring(0, 5);
  };

  const handleImageUpload = (file: File) => {
    console.log(file);
    let formData = new FormData();
    formData.append("image", file);
    apiClient
      .post("/upload/single?folder=venues", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.message === "上傳成功" && res.data.url) {
          setFormData((prev) => ({
            ...prev,
            images: [res.data.url as string],
          }));
          console.log('formData',formData);
        }
      });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">新增場地</h3>
          <button onClick={onClose}>
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded">
              {error}
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                場地名稱
              </label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                地址
              </label>
              <input
                type="text"
                required
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                攤位數量
              </label>
              <input
                type="number"
                required
                min="1"
                value={formData.totalSpots}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    totalSpots: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                每日收費
              </label>
              <input
                type="number"
                required
                min="0"
                value={formData.dailyFee}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    dailyFee: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              營業日
            </label>
            <div className="flex flex-wrap gap-2">
              {weekDays.map((day) => (
                <label key={day.value} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.operatingDays.includes(day.value)}
                    onChange={(e) => {
                      const newDays = e.target.checked
                        ? [...formData.operatingDays, day.value]
                        : formData.operatingDays.filter((d) => d !== day.value);
                      setFormData({ ...formData, operatingDays: newDays });
                    }}
                    className="rounded border-gray-300 text-orange-600 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    {day.label}
                  </span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                營業時段
              </label>
              <button
                type="button"
                onClick={addTimeSlot}
                className="text-sm text-orange-600 hover:text-orange-700 flex items-center"
              >
                <Plus className="w-4 h-4 mr-1" />
                新增時段
              </button>
            </div>
            <div className="space-y-2">
              {formData.timeSlots.map((slot) => (
                <div key={slot.id} className="flex items-center space-x-2">
                  <select
                    value={slot.period}
                    onChange={(e) => {
                      const newSlots = formData.timeSlots.map((s) =>
                        s.id === slot.id
                          ? { ...s, period: e.target.value as any }
                          : s
                      );
                      setFormData({ ...formData, timeSlots: newSlots });
                    }}
                    className="rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  >
                    <option value="morning">上午</option>
                    <option value="afternoon">下午</option>
                    <option value="evening">晚上</option>
                    <option value="custom">自定義</option>
                  </select>
                  <input
                    type="time"
                    value={slot.startTime}
                    onChange={(e) => {
                      const newSlots = formData.timeSlots.map((s) =>
                        s.id === slot.id
                          ? { ...s, startTime: formatTime(e.target.value) }
                          : s
                      );
                      setFormData({ ...formData, timeSlots: newSlots });
                    }}
                    className="rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  />
                  <span>至</span>
                  <input
                    type="time"
                    value={slot.endTime}
                    onChange={(e) => {
                      const newSlots = formData.timeSlots.map((s) =>
                        s.id === slot.id
                          ? { ...s, endTime: formatTime(e.target.value) }
                          : s
                      );
                      setFormData({ ...formData, timeSlots: newSlots });
                    }}
                    className="rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  />
                  <button
                    type="button"
                    onClick={() => removeTimeSlot(slot.id)}
                    className="text-gray-400 hover:text-red-600"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                預訂開放日
              </label>
              <input
                type="number"
                min="1"
                max="31"
                value={formData.bookingOpenDays}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    bookingOpenDays: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
              <p className="mt-1 text-sm text-gray-500">
                每月幾號開放下個月預訂
              </p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                預訂開放時間
              </label>
              <input
                type="time"
                value={formData.bookingOpenTime}
                onChange={(e) =>
                  setFormData({ ...formData, bookingOpenTime: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-4">
              預訂限制設定
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  每個餐車每月最大預訂數
                </label>
                <input
                  type="number"
                  min="1"
                  value={formData.maxDaysPerVendor}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      maxDaysPerVendor: parseInt(e.target.value),
                    })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  最少預訂天數
                </label>
                <input
                  type="number"
                  min="1"
                  value={formData.minBookingDays}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      minBookingDays: parseInt(e.target.value),
                    })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              設施
            </label>
            <div className="mt-2 space-x-2">
              {["供水", "供電", "遮雨棚", "停車位"].map((facility) => (
                <label key={facility} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.facilities.includes(facility)}
                    onChange={(e) => {
                      const newFacilities = e.target.checked
                        ? [...formData.facilities, facility]
                        : formData.facilities.filter((f) => f !== facility);
                      setFormData({ ...formData, facilities: newFacilities });
                    }}
                    className="rounded border-gray-300 text-orange-600 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">{facility}</span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              場地圖片
            </label>
            <div className="mt-1 flex items-center space-x-4">
              <div className="flex-shrink-0 h-32 w-32 border-2 border-gray-300 border-dashed rounded-lg overflow-hidden">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="預覽"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <div className="h-full w-full flex items-center justify-center">
                    <Image className="w-8 h-8 text-gray-400" />
                  </div>
                )}
              </div>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                  id="image-upload"
                />
                <label
                  htmlFor="image-upload"
                  className="cursor-pointer px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  選擇圖片
                </label>
                <p className="mt-1 text-xs text-gray-500">
                  建議尺寸: 1200x800 像素，檔案大小不超過 2MB
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              場地介紹
            </label>
            <textarea
              rows={4}
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              placeholder="請輸入場地介紹說明..."
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              取消
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
            >
              新增
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VenueForm;
