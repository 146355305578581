import React from 'react';
import useVendorStore from '../../stores/vendorStore';

const OrderList = () => {
  const orders = useVendorStore((state) => state.orders);
  const today = new Date().toISOString().split('T')[0];
  const todayOrders = orders.filter(order => order.time.includes(today));

  if (todayOrders.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        今日尚無訂單
      </div>
    );
  }

  return (
    <div className="divide-y">
      {todayOrders.map((order) => (
        <div key={order.id} className="py-4 flex justify-between items-center">
          <div>
            <div className="font-medium">{order.customer}</div>
            <div className="text-sm text-gray-500">
              {order.items.join(', ')}
            </div>
          </div>
          <div className="text-right">
            <div className="font-medium">NT${order.total}</div>
            <div className="text-sm text-gray-500">{order.time}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderList;