import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { User, Mail, Lock } from 'lucide-react';
import { toast } from 'react-toastify';
import { apiClient } from '../utils/api';
import useAuthStore from '../stores/authStore';

interface LoginForm {
  email: string;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('請輸入有效的電子郵件')
    .required('請輸入電子郵件'),
  password: yup
    .string()
    .required('請輸入密碼')
    .min(6, '密碼至少需要6個字元'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const [role, setRole] = useState<'customer' | 'vendor' | 'venue'>('customer');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data: LoginForm) => {
    try {
      if (!data.email || !data.password) {
        throw new Error('請輸入電子郵件和密碼');
      }

      const response = await apiClient.post('/auth/login', { ...data, role });
      
      if (!response.data?.success) {
        throw new Error(response.data?.message || '登入失敗，請稍後再試');
      }

      const { user, token } = response.data.data || {};
      
      if (!user || !token) {
        throw new Error('登入資料不完整');
      }

      if (user.role !== role) {
        throw new Error(`請使用正確的${getRoleName(role)}帳號登入`);
      }

      login(user, token);
      navigate(`/${role}`);
      toast.success('登入成功！');
    } catch (error: any) {
      console.error('登入失敗:', {
        error,
        response: error.response?.data,
        message: error.message
      });
      toast.error(error.message || '登入失敗，請稍後再試');
    }
  };

  const getRoleName = (role: string) => {
    switch (role) {
      case 'customer':
        return '顧客';
      case 'vendor':
        return '餐車業者';
      case 'venue':
        return '場地業者';
      default:
        return '';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center">
          <div className="flex justify-center mb-4">
            <div className="bg-orange-100 p-3 rounded-full">
              <User className="w-8 h-8 text-orange-600" />
            </div>
          </div>
          <h2 className="text-3xl font-bold text-gray-900 mb-2">用戶登入</h2>
          <p className="text-gray-600">請選擇您的身份並輸入帳號</p>
        </div>

        <div className="flex justify-center space-x-4 mb-6">
          <button
            type="button"
            onClick={() => setRole('customer')}
            className={`px-4 py-2 rounded-lg ${
              role === 'customer'
                ? 'bg-orange-500 text-white'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            顧客
          </button>
          <button
            type="button"
            onClick={() => setRole('vendor')}
            className={`px-4 py-2 rounded-lg ${
              role === 'vendor'
                ? 'bg-orange-500 text-white'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            餐車業者
          </button>
          <button
            type="button"
            onClick={() => setRole('venue')}
            className={`px-4 py-2 rounded-lg ${
              role === 'venue'
                ? 'bg-orange-500 text-white'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            場地業者
          </button>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  {...register('email')}
                  type="email"
                  className="appearance-none rounded-lg relative block w-full pl-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500"
                  placeholder="電子郵件"
                />
              </div>
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  {...register('password')}
                  type="password"
                  className="appearance-none rounded-lg relative block w-full pl-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500"
                  placeholder="密碼"
                />
              </div>
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50"
            >
              {isSubmitting ? '登入中...' : '登入'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;