import React, { useState } from 'react';
import { Search, Filter, Share2, XCircle, AlertCircle } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';

const ScheduleManager = () => {
  const [filter, setFilter] = useState('all');
  const schedules = useVendorStore((state) => state.schedules);
  const cancelSchedule = useVendorStore((state) => state.cancelSchedule);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'transferring':
        return 'bg-blue-100 text-blue-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'pending':
        return '預約中';
      case 'confirmed':
        return '已確認';
      case 'transferring':
        return '頂讓中';
      case 'cancelled':
        return '已取消';
      default:
        return '未知';
    }
  };

  const handleTransfer = (id: number) => {
    if (window.confirm('確定要申請頂讓此排程嗎？')) {
      // 實作頂讓邏輯
      console.log('申請頂讓:', id);
    }
  };

  const handleCancel = (id: number) => {
    if (window.confirm('確定要取消此排程嗎？')) {
      cancelSchedule(id);
    }
  };

  const handleReport = (id: number) => {
    // 實作回報問題邏輯
    console.log('回報問題:', id);
  };

  // 依月份分組排程
  const groupedSchedules = schedules.reduce((groups: any, schedule) => {
    const month = schedule.date.substring(0, 7); // 取得 YYYY-MM
    if (!groups[month]) {
      groups[month] = [];
    }
    groups[month].push(schedule);
    return groups;
  }, {});

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">排程管理</h2>
        <div className="flex space-x-2">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
          >
            <option value="all">所有狀態</option>
            <option value="pending">預約中</option>
            <option value="confirmed">已確認</option>
            <option value="transferring">頂讓中</option>
            <option value="cancelled">已取消</option>
          </select>
        </div>
      </div>

      <div className="space-y-8">
        {Object.entries(groupedSchedules)
          .sort(([a], [b]) => b.localeCompare(a))
          .map(([month, schedules]) => (
            <div key={month}>
              <h3 className="text-lg font-semibold mb-4">{month.replace('-', '年')}月</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {(schedules as any[])
                  .filter(schedule => filter === 'all' || schedule.status === filter)
                  .map((schedule) => (
                    <div
                      key={schedule.id}
                      className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow"
                    >
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <h3 className="text-lg font-semibold">{schedule.venueName}</h3>
                          <p className="text-sm text-gray-500">攤位編號：{schedule.spotNumber}</p>
                        </div>
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${getStatusColor(
                            schedule.status
                          )}`}
                        >
                          {getStatusText(schedule.status)}
                        </span>
                      </div>

                      <div className="space-y-2">
                        <p className="text-gray-600">地址：{schedule.address}</p>
                        <p className="text-gray-600">使用日期：{schedule.date}</p>
                        <p className="text-gray-600">營業時段：{schedule.timeSlot}</p>
                        <p className="text-gray-600">
                          費用：NT${schedule.pricePerDay}/天 × {schedule.bookedDays}天
                        </p>
                      </div>

                      {schedule.status === 'confirmed' && (
                        <div className="mt-4 pt-4 border-t grid grid-cols-3 gap-2">
                          <button
                            onClick={() => handleTransfer(schedule.id)}
                            className="flex items-center justify-center px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                          >
                            <Share2 className="w-4 h-4 mr-1" />
                            申請頂讓
                          </button>
                          <button
                            onClick={() => handleCancel(schedule.id)}
                            className="flex items-center justify-center px-3 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                          >
                            <XCircle className="w-4 h-4 mr-1" />
                            取消排程
                          </button>
                          <button
                            onClick={() => handleReport(schedule.id)}
                            className="flex items-center justify-center px-3 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700"
                          >
                            <AlertCircle className="w-4 h-4 mr-1" />
                            回報問題
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ScheduleManager;