import React, { useState } from "react";
import {
  X,
  Calendar,
  Clock,
  Settings,
  Image,
  Upload,
  Edit,
  Trash,
  DollarSign,
  Plus,
  Trash2,
} from "lucide-react";
import VenueLayoutEditor from "./VenueLayoutEditor";
import useVenueStore from "../../stores/venueStore";
import { apiClient } from "../../utils/api";

interface VenueDetailProps {
  venue: any;
  onClose: () => void;
}

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface Settings {
  name: string;
  address: string;
  description: string;
  totalSpots: number;
  availableSpots: number;
  dailyFee: number;
  bookingOpenDays: number;
  minBookingDays: number;
  maxDaysPerVendor: number;
  maxBookingDays: number;
  bookingOpenTime: string;
  bookingOpenDay: number;
  timeSlots: TimeSlot[];
  facilities: string[];
  operatingDays: string[];
  images: string[];
  image: string;
  layoutImage?: string | null;
  owner: {
    name: string;
    phone: string;
    email: string;
    taxId: string;
    address: string;
    bankAccount: string;
    bankName: string;
    bankBranch: string;
  };
}

const VenueDetail: React.FC<VenueDetailProps> = ({ venue, onClose }) => {
  console.log("VenueDetail 初始化 venue:", venue);

  const [activeTab, setActiveTab] = useState("layout");
  const updateVenue = useVenueStore((state) => state.updateVenue);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imagePreview, setImagePreview] = useState<string>(venue.image || "");

  const [settings, setSettings] = useState<Settings>(() => {
    return {
      name: venue?.name || "",
      address: venue?.address || "",
      description: venue?.description || "",
      totalSpots: Number(venue?.totalSpots) || 0,
      availableSpots: Number(venue?.availableSpots) || 0,
      dailyFee: Number(venue?.dailyFee) || 0,
      bookingOpenDays: Number(venue?.bookingOpenDays) || 1,
      minBookingDays: Number(venue?.minBookingDays) || 1,
      maxDaysPerVendor: Number(venue?.maxDaysPerVendor) || 7,
      maxBookingDays: Number(venue?.maxBookingDays) || 30,
      bookingOpenTime: venue?.bookingOpenTime || "09:00",
      bookingOpenDay: Number(venue?.bookingOpenDay) || 1,
      timeSlots: Array.isArray(venue?.timeSlots)
        ? venue.timeSlots.map((slot: TimeSlot) => ({
            startTime: slot?.startTime || "00:00",
            endTime: slot?.endTime || "00:00",
          }))
        : [],
      facilities: Array.isArray(venue?.facilities) ? venue.facilities : [],
      operatingDays: Array.isArray(venue?.operatingDays)
        ? venue.operatingDays
        : [],
      images: Array.isArray(venue?.images) ? venue.images : [],
      image: venue?.image || "",
      owner: {
        name: venue?.owner?.name || "",
        phone: venue?.owner?.phone || "",
        email: venue?.owner?.email || "",
        taxId: venue?.owner?.taxId || "",
        address: venue?.owner?.address || "",
        bankAccount: venue?.owner?.bankAccount || "",
        bankName: venue?.owner?.bankName || "",
        bankBranch: venue?.owner?.bankBranch || "",
      },
    };
  });

  const [customFacility, setCustomFacility] = useState("");

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // 檢查文件大小
      if (file.size > 2 * 1024 * 1024) {
        // 2MB
        alert("圖片大小不能超過 2MB");
        return;
      }

      try {
        // 壓縮圖片
        const compressedFile = await compressImage(file, {
          maxWidth: 1200,
          maxHeight: 800,
          quality: 0.8,
        });

        setImageFiles([...imageFiles, compressedFile]);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(compressedFile);
        let formData = new FormData();
        formData.append("image", file);
        apiClient
          .post("/upload/single?folder=venues", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.message === "上傳成功" && res.data.url) {
              console.log("settings", settings);
              setSettings({
                ...settings,
                images: [...settings.images, res.data.url as string],
              });
              console.log("settings", settings);
              console.log("formData", formData);
            }
          });
      } catch (error) {
        console.error("圖片處理失敗:", error);
        alert("圖片處理失敗，請稍後再試");
      }
    }
  };

  // 圖片壓縮函數
  const compressImage = (
    file: File,
    _options: { maxWidth: number; maxHeight: number; quality: number }
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new (window.Image as { new (): HTMLImageElement })();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let { width, height } = img;

        // 更嚴格的尺寸限制
        const maxWidth = 800;
        const maxHeight = 600;

        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = (width * maxHeight) / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        // 降低圖片質量
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const compressedFile = new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            } else {
              reject(new Error("圖片壓縮失敗"));
            }
          },
          "image/jpeg",
          0.6 // 降低質量到 60%
        );
      };
      img.onerror = () => reject(new Error("圖片載入失敗"));
    });
  };

  const handleSaveSettings = async () => {
    try {
      console.log("準備儲存設定:", settings);

      let imageUrls = [...settings.images];

      // if (imageFiles.length > 0) {
      //   const formData = new FormData();
      //   formData.append("image", imageFiles[0]);

      //   try {
      //     const response = await fetch("/api/upload/single?folder=venues", {
      //       method: "POST",
      //       body: formData,
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     });

      //     if (!response.ok) {
      //       const errorData = await response.json();
      //       throw new Error(
      //         errorData.message ||
      //         `上傳失敗: ${response.status} ${response.statusText}`
      //       );
      //     }

      //     const data = await response.json();
      //     if (!data.url) {
      //       throw new Error("上傳回應少圖片URL");
      //     }

      //     imageUrls = [...imageUrls, data.url];
      //   } catch (error: any) {
      //     console.error("圖片上傳錯誤:", error);
      //     throw new Error(`圖片上傳失敗: ${error.message}`);
      //   }
      // }

      const updatedData = {
        ...settings,
        images: imageUrls,
        name: settings.name || venue.name,
        address: settings.address || venue.address,
        description: settings.description || "",
        dailyFee:
          settings.dailyFee !== undefined ? Number(settings.dailyFee) : 0,
        totalSpots:
          settings.totalSpots !== undefined ? Number(settings.totalSpots) : 0,
        availableSpots:
          settings.availableSpots !== undefined
            ? Number(settings.availableSpots)
            : 0,
        bookingOpenDays:
          settings.bookingOpenDays !== undefined
            ? Number(settings.bookingOpenDays)
            : 1,
        minBookingDays:
          settings.minBookingDays !== undefined
            ? Number(settings.minBookingDays)
            : 1,
        maxDaysPerVendor:
          settings.maxDaysPerVendor !== undefined
            ? Number(settings.maxDaysPerVendor)
            : 7,
        maxBookingDays:
          settings.maxBookingDays !== undefined
            ? Number(settings.maxBookingDays)
            : 30,
        bookingOpenTime: settings.bookingOpenTime || "09:00",
        timeSlots: settings.timeSlots || [],
        facilities: settings.facilities || [],
        operatingDays: settings.operatingDays || [],
        status: "active",
        owner: settings.owner,
      };

      // 加入測試日誌
      console.log("更新前的設定:", settings);
      console.log("準備更新的數據:", updatedData);

      const result = await updateVenue(venue.id, updatedData);
      if (result) {
        console.log("設定儲存成功");
        onClose();
      }
    } catch (error: any) {
      console.error("儲存設定失敗:", error);
      alert(error.message || "儲存設定失敗，請稍後再試");
    }
  };

  const tabs = [
    { id: "layout", label: "場地配置", icon: Settings },
    { id: "operating", label: "營業設定", icon: Clock },
  ];

  const weekDays = [
    { value: "monday", label: "週一" },
    { value: "tuesday", label: "週二" },
    { value: "wednesday", label: "週三" },
    { value: "thursday", label: "週四" },
    { value: "friday", label: "週五" },
    { value: "saturday", label: "週六" },
    { value: "sunday", label: "週日" },
  ];

  const renderOperatingSettings = () => {
    return (
      <div className="p-6 space-y-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">營業設定</h3>

          {/* 場地圖片 */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              場地圖片
            </label>
            <div className="mt-2 flex items-center gap-4">
              {settings.images.map(
                (
                  image: string | undefined,
                  index: React.Key | null | undefined
                ) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`場地圖片 ${String(Number(index) + 1)}`}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => {
                        const newImages = settings.images.filter(
                          (_: any, i: any) => i !== index
                        );
                        setSettings({ ...settings, images: newImages });
                      }}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                )
              )}

              {settings.images.length < 3 && (
                <div className="flex justify-center items-center w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg">
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => {
                        // const file = e.target.files?.[0];
                        // if (file) {
                        // setImageFiles([...imageFiles, file]);
                        // const reader = new FileReader();
                        // reader.onload = (e) => {
                        //   setSettings({
                        //     ...settings,
                        //     images: [
                        //       ...settings.images,
                        //       e.target?.result as string,
                        //     ],
                        //   });
                        // };
                        // reader.readAsDataURL(file);
                        // }
                        handleImageChange(e);
                      }}
                    />
                    <div className="text-center">
                      <Upload className="mx-auto w-6 h-6 text-gray-400" />
                      <p className="mt-1 text-sm text-gray-500">選擇圖片</p>
                      <p className="text-xs text-gray-400">
                        建議寸: 1200x800 像素
                      </p>
                      <p className="text-xs text-gray-400">
                        檔案大小不超過 2MB
                      </p>
                    </div>
                  </label>
                </div>
              )}
            </div>
            <p className="mt-2 text-sm text-gray-500">
              最少 1 張，最多 3 張圖片
            </p>
          </div>

          {/* 場地平面圖 - 移到場地介紹上方 */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              場地平面圖
            </label>
            {settings.layoutImage ? (
              <div className="mt-2 relative">
                <img
                  src={settings.layoutImage}
                  alt="場地平面圖"
                  className="w-full max-w-2xl rounded-lg"
                />
                <button
                  onClick={() =>
                    setSettings({ ...settings, layoutImage: null })
                  }
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="mt-2 text-sm text-gray-500">
                尚未設置場地平面圖，請在場地配置頁面使用「導出到營業設定」功能
              </div>
            )}
          </div>

          {/* 場地介紹 */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              場地介紹
            </label>
            <textarea
              value={settings.description}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  description: e.target.value,
                })
              }
              rows={4}
              maxLength={255}
              placeholder="請輸入場地介紹說明..."
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
            <p className="mt-1 text-sm text-gray-500">最多255字</p>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              攤位數量
            </label>
            <input
              type="number"
              min="1"
              value={settings.totalSpots}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  totalSpots: parseInt(e.target.value),
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              每日收費
            </label>
            <input
              type="number"
              min="0"
              value={settings.dailyFee}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  dailyFee: parseInt(e.target.value),
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              營業日
            </label>
            <div className="mt-2 flex flex-wrap gap-2">
              {weekDays.map((day) => (
                <label key={day.value} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.operatingDays.includes(day.value)}
                    onChange={(e) => {
                      const newDays = e.target.checked
                        ? [...settings.operatingDays, day.value]
                        : settings.operatingDays.filter(
                            (d: string) => d !== day.value
                          );
                      setSettings({
                        ...settings,
                        operatingDays: newDays,
                      });
                    }}
                    className="rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                  />
                  <span className="ml-2">{day.label}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                營業時段
              </label>
              <button
                type="button"
                onClick={() => {
                  setSettings({
                    ...settings,
                    timeSlots: [
                      ...settings.timeSlots,
                      { startTime: "00:00", endTime: "00:00" },
                    ],
                  });
                }}
                className="text-sm text-orange-600 hover:text-orange-700 flex items-center"
              >
                <Plus className="w-4 h-4 mr-1" />
                新增時段
              </button>
            </div>
            {settings.timeSlots.map((slot: TimeSlot, index: number) => (
              <div key={index} className="flex items-center gap-4 mt-2">
                <input
                  type="time"
                  value={slot.startTime}
                  onChange={(e) => {
                    const newSlots = [...settings.timeSlots];
                    newSlots[index as number].startTime = e.target.value;
                    setSettings({
                      ...settings,
                      timeSlots: newSlots,
                    });
                  }}
                  className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
                <span>至</span>
                <input
                  type="time"
                  value={slot.endTime}
                  onChange={(e) => {
                    const newSlots = [...settings.timeSlots];
                    newSlots[index as number].endTime = e.target.value;
                    setSettings({
                      ...settings,
                      timeSlots: newSlots,
                    });
                  }}
                  className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                />
                <button
                  type="button"
                  onClick={() => {
                    const newSlots = settings.timeSlots.filter(
                      (_: any, i: any) => i !== index
                    );
                    setSettings({
                      ...settings,
                      timeSlots: newSlots,
                    });
                  }}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              預訂開放日
            </label>
            <input
              type="number"
              min="1"
              value={settings.bookingOpenDays}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  bookingOpenDays: parseInt(e.target.value),
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
            <p className="mt-1 text-sm text-gray-500">每月幾號開放下個月預訂</p>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              預訂開放時間
            </label>
            <input
              type="time"
              value={settings.bookingOpenTime}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  bookingOpenTime: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                每個餐車每月最大預訂數
              </label>
              <input
                type="number"
                min="1"
                value={settings.maxDaysPerVendor}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    maxDaysPerVendor: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                最少預訂天數
              </label>
              <input
                type="number"
                min="1"
                value={settings.minBookingDays}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    minBookingDays: parseInt(e.target.value),
                  })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              設施
            </label>
            <div className="mt-2 flex flex-wrap gap-2">
              {["供水", "供電", "遮雨棚", "停車位"].map((facility) => (
                <label key={facility} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.facilities.includes(facility)}
                    onChange={(e) => {
                      const newFacilities = e.target.checked
                        ? [...settings.facilities, facility]
                        : settings.facilities.filter(
                            (f: string) => f !== facility
                          );
                      setSettings({
                        ...settings,
                        facilities: newFacilities,
                      });
                    }}
                    className="rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                  />
                  <span className="ml-2">{facility}</span>
                </label>
              ))}

              {/* 其他設施輸入框 */}
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  placeholder="其他設施"
                  maxLength={10}
                  value={customFacility}
                  onChange={(e) => setCustomFacility(e.target.value)}
                  className="px-2 py-1 text-sm border rounded-md border-gray-300 focus:border-orange-500 focus:ring-orange-500"
                />
                <button
                  type="button"
                  onClick={() => {
                    if (
                      customFacility.trim() &&
                      !settings.facilities.includes(customFacility.trim())
                    ) {
                      setSettings({
                        ...settings,
                        facilities: [
                          ...settings.facilities,
                          customFacility.trim(),
                        ],
                      });
                      setCustomFacility("");
                    }
                  }}
                  className="px-2 py-1 text-sm bg-orange-600 text-white rounded-md hover:bg-orange-700"
                >
                  新增
                </button>
              </div>
            </div>

            {/* 已添加的自定義設施 */}
            <div className="mt-2 flex flex-wrap gap-2">
              {settings.facilities
                .filter(
                  (f: string) =>
                    !["供水", "供電", "遮雨棚", "停車位"].includes(f)
                )
                .map((facility: string) => (
                  <span
                    key={facility}
                    className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-full text-sm"
                  >
                    {facility}
                    <button
                      type="button"
                      onClick={() => {
                        setSettings({
                          ...settings,
                          facilities: settings.facilities.filter(
                            (f: any) => f !== facility
                          ),
                        });
                      }}
                      className="ml-1 text-gray-500 hover:text-red-600"
                    >
                      <X className="w-3 h-3" />
                    </button>
                  </span>
                ))}
            </div>
          </div>

          {/* 儲存按鈕 */}
          <div className="mt-8 flex justify-end">
            <button
              onClick={handleSaveSettings}
              className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
            >
              儲存設定
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "layout":
        return <VenueLayoutEditor venue={venue} setSettings={setSettings} />;
      case "operating":
        return renderOperatingSettings();
      default:
        return null;
    }
  };
  if (!venue) {
    return <div>載入中...</div>;
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[90vw] h-[90vh] flex flex-col">
        <div className="p-4 border-b flex justify-between items-center">
          <div>
            <h3 className="text-xl font-semibold">{venue.name}</h3>
            <p className="text-sm text-gray-500">場地管理</p>
          </div>
          <button onClick={onClose}>
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex border-b">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center px-6 py-3 ${
                activeTab === tab.id
                  ? "border-b-2 border-orange-600 text-orange-600"
                  : "text-gray-600 hover:text-gray-900"
              }`}
            >
              <tab.icon className="w-5 h-5 mr-2" />
              {tab.label}
            </button>
          ))}
        </div>

        <div className="flex-1 overflow-auto">{renderContent()}</div>
      </div>
    </div>
  );
};

export default VenueDetail;
