import React from 'react';
import { ChevronRight } from 'lucide-react';

const MenuSection = () => {
  const menuCategories = [
    {
      name: '主食',
      items: [
        { id: 1, name: '招牌滷肉飯', price: 60, description: '使用特製滷汁醬料，搭配油亮軟嫩的滷肉', image: 'https://images.unsplash.com/photo-1617622141573-2f54dc392cdf?auto=format&fit=crop&q=80' },
        { id: 2, name: '排骨飯', price: 80, description: '香酥多汁的排骨，搭配特製醬料', image: 'https://images.unsplash.com/photo-1574071318508-1cdbab80d002?auto=format&fit=crop&q=80' },
      ],
    },
    {
      name: '小菜',
      items: [
        { id: 3, name: '滷蛋', price: 15, description: '入味的滷蛋，口感紮實', image: 'https://images.unsplash.com/photo-1618436917352-cd3d11ea4d15?auto=format&fit=crop&q=80' },
        { id: 4, name: '滷豆干', price: 20, description: '入味的滷豆干，香Q可口', image: 'https://images.unsplash.com/photo-1617622141573-2f54dc392cdf?auto=format&fit=crop&q=80' },
      ],
    },
  ];

  return (
    <div className="space-y-8">
      {menuCategories.map((category) => (
        <div key={category.name}>
          <h3 className="text-xl font-bold mb-4">{category.name}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {category.items.map((item) => (
              <div
                key={item.id}
                className="flex space-x-4 bg-white rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-24 h-24 rounded-lg object-cover"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-semibold">{item.name}</h4>
                      <p className="text-sm text-gray-500 mt-1">{item.description}</p>
                    </div>
                    <span className="text-orange-600 font-semibold">NT${item.price}</span>
                  </div>
                  <button className="mt-2 text-sm text-orange-600 hover:text-orange-700 flex items-center">
                    加入訂單
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuSection;