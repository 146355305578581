import React, { useState, useEffect } from "react";
import {
  Calendar,
  MapPin,
  Plus,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";
import { format, addMonths, startOfMonth, isBefore, isAfter } from "date-fns";
import useVenueStore from "../../stores/venueStore";
import MonthlyBookingForm from "./MonthlyBookingForm";
import VenueCalendar from "./VenueCalendar";

const BookingManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState("all"); // 'all', 'active', 'expired'
  const venues = useVenueStore((state) => state.venues);
  const monthlyBookings = useVenueStore((state) => state.monthlyBookings);
  const [selectedMonthlyBooking, setSelectedMonthlyBooking] =
    useState<any>(null);
  const fetchMonthlyBookings = useVenueStore(
    (state) => state.fetchMonthlyBookings
  );
  useEffect(() => {
    fetchMonthlyBookings(0);
  }, [fetchMonthlyBookings]);
  const filterBookings = (bookings: any[]) => {
    const today = new Date();
    switch (filter) {
      case "active":
        return bookings.filter((b) => {
          const bookingDate = new Date(b.month);
          return (
            isAfter(bookingDate, today) ||
            isBefore(bookingDate, addMonths(today, 1))
          );
        });
      case "expired":
        return bookings.filter((b) => {
          const bookingDate = new Date(b.month);
          return isBefore(bookingDate, today);
        });
      default:
        return bookings;
    }
  };

  const groupedBookings = filterBookings(monthlyBookings).reduce(
    (groups: any, booking) => {
      if (!groups[booking.month]) {
        groups[booking.month] = [];
      }
      groups[booking.month].push(booking);
      return groups;
    },
    {}
  );

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">預約管理</h2>
        <div className="flex space-x-2">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
          >
            <option value="all">顯示全部</option>
            <option value="active">進行中</option>
            <option value="expired">已過期</option>
          </select>
          <button
            onClick={() => setShowForm(true)}
            className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            開放月份預訂
          </button>
        </div>
      </div>

      <div className="space-y-8">
        {Object.entries(groupedBookings)
          .sort(([a], [b]) => b.localeCompare(a))
          .map(([month, bookings]) => (
            <div key={month}>
              <h3 className="text-lg font-semibold mb-4">
                {month.replace("-", "年")}月
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {(bookings as any[]).map((booking) => {
                  const venue = venues.find((v) => v.id === booking.venueId);
                  if (!venue) return null;

                  return (
                    <div
                      key={booking.id}
                      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                      onClick={() => {
                        setSelectedMonthlyBooking({ booking, venue });
                      }}
                    >
                      <div className="h-48 relative">
                        <img
                          src={venue.image}
                          alt={venue.name}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute top-4 right-4">
                          <span className="px-3 py-1 bg-green-500 text-white rounded-full text-sm font-medium">
                            已開放預訂
                          </span>
                        </div>
                      </div>

                      <div className="p-6">
                        <h3 className="text-lg font-semibold mb-2">
                          {venue.name}
                        </h3>
                        <p className="text-sm text-gray-500 mb-2 flex items-center">
                          <MapPin className="w-4 h-4 mr-1" />
                          {venue.address}
                        </p>
                        <p className="text-sm text-orange-600 mb-4 font-medium">
                          每日收費：NT$ {venue.dailyFee?.toLocaleString()}
                        </p>

                        <div className="space-y-3">
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-600">
                              剩餘攤位
                            </span>
                            <span className="font-medium">
                              {booking.availableSpots} / {venue.totalSpots}
                            </span>
                          </div>

                          <div className="w-full bg-gray-200 rounded-full h-2">
                            <div
                              className="bg-orange-500 h-2 rounded-full"
                              style={{
                                width: `${
                                  ((venue.totalSpots - booking.availableSpots) /
                                    venue.totalSpots) *
                                  100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      </div>

      {showForm && <MonthlyBookingForm onClose={() => setShowForm(false)} />}
      {selectedMonthlyBooking && (
        <VenueCalendar
          venue={selectedMonthlyBooking.venue}
          onClose={() => {
            setSelectedMonthlyBooking(null)
            fetchMonthlyBookings(0)
          }}
        />
      )}
    </div>
  );
};

export default BookingManager;
