import { X, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Application {
  id: string;
  vendorId: string;
  vendorName: string;
  vendorPhone: string;
  status: 'pending' | 'approved' | 'rejected';
  appliedAt: string;
  selectedDays: string[];
  timeSlot: string;
  notes: string;
}

interface ApplicationListProps {
  venueId: string;
  onClose: () => void;
}

const ApplicationList: React.FC<ApplicationListProps> = ({ onClose }) => {
  const navigate = useNavigate();
  
  // 模擬申請資料
  const applications: Application[] = [
    {
      id: '1',
      vendorId: 'truck-1',
      vendorName: '美食餐車',
      vendorPhone: '0912-345-678',
      status: 'pending',
      appliedAt: '2024-03-15',
      selectedDays: ['2024-04-01', '2024-04-02', '2024-04-03'],
      timeSlot: '上午',
      notes: '主打台式早餐'
    },
    {
      id: '2',
      vendorId: 'truck-2',
      vendorName: '漢堡餐車',
      vendorPhone: '0923-456-789',
      status: 'approved',
      appliedAt: '2024-03-14',
      selectedDays: ['2024-04-05', '2024-04-06'],
      timeSlot: '下午',
      notes: '美式漢堡'
    }
  ];

  const getStatusBadge = (status: 'pending' | 'approved' | 'rejected') => {
    const styles: Record<typeof status, string> = {
      pending: 'bg-yellow-100 text-yellow-800',
      approved: 'bg-green-100 text-green-800',
      rejected: 'bg-red-100 text-red-800'
    };
    const labels: Record<typeof status, string> = {
      pending: '審核中',
      approved: '已核准',
      rejected: '已拒絕'
    };
    
    return (
      <span className={`px-2 py-1 rounded-full text-sm ${styles[status]}`}>
        {labels[status]}
      </span>
    );
  };

  const handleViewVendor = (vendorId: string) => {
    onClose();
    navigate(`/trucks/${vendorId}`);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold">申請列表</h3>
          <button onClick={onClose}>
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          {applications.map(app => (
            <div key={app.id} className="border rounded-lg p-4">
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center space-x-2">
                  <h4 className="font-medium">{app.vendorName}</h4>
                  <button
                    onClick={() => handleViewVendor(app.vendorId)}
                    className="text-orange-600 hover:text-orange-700 inline-flex items-center"
                  >
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </button>
                </div>
                {getStatusBadge(app.status)}
              </div>
              
              <div className="space-y-2">
                <p className="text-sm">
                  <span className="text-gray-500">申請日期：</span>
                  {app.appliedAt}
                </p>
                <p className="text-sm">
                  <span className="text-gray-500">預訂日期：</span>
                  {app.selectedDays.join(', ')}
                </p>
                <p className="text-sm">
                  <span className="text-gray-500">時段：</span>
                  {app.timeSlot}
                </p>
                <p className="text-sm">
                  <span className="text-gray-500">備註：</span>
                  {app.notes}
                </p>
              </div>

              {app.status === 'pending' && (
                <div className="mt-4 flex space-x-2">
                  <button className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
                    核准
                  </button>
                  <button className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
                    拒絕
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationList; 