import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VendorLayout from '../components/layouts/VendorLayout';
import VendorHome from '../components/vendor/VendorHome';
import MenuManager from '../components/vendor/MenuManager';
import ScheduleManager from '../components/vendor/ScheduleManager';
import SpotApplication from '../components/vendor/SpotApplication';
import Analytics from '../components/vendor/Analytics';

const VendorDashboard = () => {
  return (
    <VendorLayout>
      <Routes>
        <Route index element={<VendorHome />} />
        <Route path="menu" element={<MenuManager />} />
        <Route path="schedule" element={<ScheduleManager />} />
        <Route path="spots" element={<SpotApplication />} />
        <Route path="analytics" element={<Analytics />} />
      </Routes>
    </VendorLayout>
  );
};

export default VendorDashboard;