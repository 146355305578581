import React, { useState } from 'react';
import { Search, MapPin, Calendar } from 'lucide-react';
import { DayPicker } from 'react-day-picker';
import { zhTW } from 'date-fns/locale';
import { isSameMonth } from 'date-fns';
import useVenueStore from '../../stores/venueStore';
import 'react-day-picker/dist/style.css';

const SpotApplication = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArea, setSelectedArea] = useState('all');
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState<any>(null);
  const monthlyBookings = useVenueStore((state) => state.monthlyBookings);
  const venues = useVenueStore((state) => state.venues);

  const areas = ['all', '信義區', '中山區', '大安區', '松山區'];

  // 依月份分組可申請的場地
  const groupedVenues = monthlyBookings.reduce((groups: any, booking: { openForBooking: any; venueId: number; month: string | number; availableSpots: any; }) => {
    if (!booking.openForBooking) return groups;
    
    const venue = venues.find(v => v.id === booking.venueId);
    if (!venue) return groups;

    if (!groups[booking.month]) {
      groups[booking.month] = [];
    }
    groups[booking.month].push({
      ...venue,
      availableSpots: booking.availableSpots,
      month: booking.month,
    });
    return groups;
  }, {});

  const filteredVenues = (venues: any[]) => {
    return venues.filter(venue => {
      const matchQuery = venue.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        venue.address.toLowerCase().includes(searchQuery.toLowerCase());
      const matchArea = selectedArea === 'all' || venue.address.includes(selectedArea);
      return matchQuery && matchArea;
    });
  };

  const handleApply = (venue: any) => {
    setSelectedVenue(venue);
    setShowApplicationForm(true);
  };

  const ApplicationForm = () => {
    const currentMonth = new Date(selectedVenue.month);
    const maxDaysPerMonth = 15; // 設定每月最大可申請天數
    const [formData, setFormData] = useState({
      selectedDays: [] as Date[],
      timeSlot: '',
      notes: '',
    });

    const fromDate = currentMonth;
    const toDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

    const handleDayClick = (day: Date) => {
      const newSelectedDays = formData.selectedDays.includes(day)
        ? formData.selectedDays.filter(d => d !== day)
        : [...formData.selectedDays, day];

      if (newSelectedDays.length <= maxDaysPerMonth) {
        setFormData({ ...formData, selectedDays: newSelectedDays });
      }
    };

    const footer = (
      <p className="text-sm text-gray-500">
        已選擇 {formData.selectedDays.length} 天
        {formData.selectedDays.length >= maxDaysPerMonth && ' (已達上限)'}
      </p>
    );

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      console.log('提交申請:', {
        venue: selectedVenue,
        ...formData,
      });
      setShowApplicationForm(false);
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">攤位申請 - {selectedVenue.name}</h3>
            <button
              onClick={() => setShowApplicationForm(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="border rounded-lg p-4">
              <h4 className="font-medium mb-2">選擇使用日期</h4>
              <p className="text-sm text-gray-500 mb-4">
                每月最多可選擇 {maxDaysPerMonth} 天
              </p>
              <div className="flex justify-center">
                <DayPicker
                  mode="multiple"
                  selected={formData.selectedDays}
                  onDayClick={handleDayClick}
                  fromDate={fromDate}
                  toDate={toDate}
                  locale={zhTW}
                  footer={footer}
                  modifiers={{
                    disabled: [
                      (date) => !isSameMonth(date, currentMonth),
                    ],
                  }}
                  modifiersStyles={{
                    selected: {
                      backgroundColor: '#ea580c',
                    },
                  }}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">營業時段</label>
              <select
                required
                value={formData.timeSlot}
                onChange={(e) => setFormData({ ...formData, timeSlot: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              >
                <option value="">選擇時段</option>
                <option value="morning">早上 (06:00-14:00)</option>
                <option value="afternoon">下午 (14:00-22:00)</option>
                <option value="full">全天 (10:00-22:00)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">備註</label>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                placeholder="如有特殊需求請在此說明..."
              />
            </div>

            <div className="flex justify-end space-x-2 pt-4">
              <button
                type="button"
                onClick={() => setShowApplicationForm(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                取消
              </button>
              <button
                type="submit"
                disabled={formData.selectedDays.length === 0}
                className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                提交申請
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">攤位申請</h2>
        
        {/* 搜尋和篩選 */}
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="搜尋場地名稱或地址..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <select
            value={selectedArea}
            onChange={(e) => setSelectedArea(e.target.value)}
            className="w-full md:w-48 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
          >
            {areas.map(area => (
              <option key={area} value={area}>
                {area === 'all' ? '所有地區' : area}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* 依月份顯示可申請場地 */}
      <div className="space-y-8">
        {Object.entries(groupedVenues)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([month, venues]) => (
            <div key={month}>
              <h3 className="text-lg font-semibold mb-4">{month.replace('-', '年')}月</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredVenues(venues as any[]).map((venue) => (
                  <div
                    key={`${venue.id}-${month}`}
                    className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                  >
                    <div className="h-48 relative">
                      <img
                        src={venue.image}
                        alt={venue.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-4 right-4">
                        <span className="px-3 py-1 bg-green-500 text-white rounded-full text-sm">
                          開放申請中
                        </span>
                      </div>
                    </div>

                    <div className="p-6">
                      <h4 className="text-lg font-semibold mb-2">{venue.name}</h4>
                      <div className="space-y-2 mb-4">
                        <p className="flex items-center text-gray-600">
                          <MapPin className="w-4 h-4 mr-2" />
                          {venue.address}
                        </p>
                        <p className="flex items-center text-gray-600">
                          <Calendar className="w-4 h-4 mr-2" />
                          剩餘攤位：{venue.availableSpots}/{venue.totalSpots}
                        </p>
                      </div>

                      <div className="space-y-2">
                        <div className="text-sm text-gray-500">
                          每日收費：<span className="text-orange-600 font-semibold">NT${venue.pricePerDay}</span>
                        </div>
                        <button
                          onClick={() => handleApply(venue)}
                          className="w-full px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                        >
                          申請攤位
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>

      {showApplicationForm && selectedVenue && <ApplicationForm />}
    </div>
  );
};

export default SpotApplication;