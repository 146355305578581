import React, { useState } from 'react';
import { X } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';

interface MenuFormProps {
  onClose: () => void;
  editItem?: any;
}

const MenuForm: React.FC<MenuFormProps> = ({ onClose, editItem }) => {
  const addMenuItem = useVendorStore((state) => state.addMenuItem);
  const updateMenuItem = useVendorStore((state) => state.updateMenuItem);

  const [formData, setFormData] = useState({
    name: editItem?.name || '',
    price: editItem?.price || '',
    description: editItem?.description || '',
    category: editItem?.category || '主食',
    image: editItem?.image || '',
    available: editItem?.available ?? true,
    preOrder: editItem?.preOrder ?? false,
    preOrderDeadlineTime: editItem?.preOrderDeadlineTime || '13:00',
    preOrderLimit: editItem?.preOrderLimit || '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const menuItem = {
      ...formData,
      price: Number(formData.price),
      preOrderLimit: formData.preOrder ? Number(formData.preOrderLimit) : null,
    };
    if (editItem) {
      updateMenuItem(editItem.id, {
        ...menuItem,
        preOrderLimit: menuItem.preOrderLimit ?? undefined
      });
    } else {
      addMenuItem({
        ...menuItem,
        preOrderLimit: menuItem.preOrderLimit ?? undefined
      });
    }
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {editItem ? '編輯品項' : '新增品項'}
          </h3>
          <button onClick={onClose}>
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">品項名稱</label>
            <input
              type="text"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">價格</label>
            <input
              type="number"
              required
              min="0"
              value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">描述</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">分類</label>
            <select
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            >
              <option value="主食">主食</option>
              <option value="小菜">小菜</option>
              <option value="飲料">飲料</option>
              <option value="甜點">甜點</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">圖片網址</label>
            <input
              type="url"
              value={formData.image}
              onChange={(e) => setFormData({ ...formData, image: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="available"
                checked={formData.available}
                onChange={(e) => setFormData({ ...formData, available: e.target.checked })}
                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
              />
              <label htmlFor="available" className="ml-2 block text-sm text-gray-700">
                立即供應
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="preOrder"
                checked={formData.preOrder}
                onChange={(e) => setFormData({ ...formData, preOrder: e.target.checked })}
                className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
              />
              <label htmlFor="preOrder" className="ml-2 block text-sm text-gray-700">
                開放預訂
              </label>
            </div>

            {formData.preOrder && (
              <div className="pl-6 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    預訂截止時間
                  </label>
                  <input
                    type="time"
                    value={formData.preOrderDeadlineTime}
                    onChange={(e) => setFormData({ ...formData, preOrderDeadlineTime: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  />
                  <p className="mt-1 text-sm text-gray-500">當日此時間後將停止接受預訂</p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    預訂數量限制
                  </label>
                  <input
                    type="number"
                    min="0"
                    value={formData.preOrderLimit}
                    onChange={(e) => setFormData({ ...formData, preOrderLimit: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                    placeholder="不限制請留空"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              取消
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
            >
              {editItem ? '更新' : '新增'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MenuForm;