import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Shield, Users, FileText, Settings, Bell, Menu as MenuIcon, LogOut } from 'lucide-react';
import useAuthStore from '../stores/authStore';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { logout } = useAuthStore();

  const adminInfo = {
    name: '系統管理員',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80',
    notifications: 5,
  };

  const menuItems = [
    { id: 'dashboard', label: '儀表板', icon: Shield, path: '/admin/dashboard' },
    { id: 'users', label: '用戶管理', icon: Users, path: '/admin/users' },
    { id: 'content', label: '內容審核', icon: FileText, path: '/admin/content' },
    { id: 'settings', label: '系統設置', icon: Settings, path: '/admin/settings' },
  ];

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* 側邊欄 */}
      <div className={`bg-white shadow-lg ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300`}>
        <div className="p-4">
          <div className="flex items-center space-x-4">
            <div className="bg-orange-100 p-2 rounded-lg">
              <Shield className="w-8 h-8 text-orange-600" />
            </div>
            {isSidebarOpen && (
              <div className="flex-1">
                <h2 className="font-semibold">管理員控制台</h2>
                <p className="text-sm text-gray-500">系統管理</p>
              </div>
            )}
          </div>
        </div>

        <nav className="mt-8">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => navigate(item.path)}
              className={`w-full flex items-center px-4 py-3 ${
                location.pathname === item.path
                  ? 'bg-orange-50 text-orange-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <item.icon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-4">{item.label}</span>}
            </button>
          ))}
          
          {/* 登出按鈕 */}
          <button
            onClick={handleLogout}
            className="w-full flex items-center px-4 py-3 text-red-600 hover:bg-red-50 mt-4"
          >
            <LogOut className="w-5 h-5" />
            {isSidebarOpen && <span className="ml-4">登出</span>}
          </button>
        </nav>
      </div>

      {/* 主要內容區 */}
      <div className="flex-1">
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-6 py-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="text-gray-500 hover:text-gray-700"
            >
              <MenuIcon className="w-6 h-6" />
            </button>

            <div className="flex items-center space-x-4">
              <button className="relative text-gray-500 hover:text-gray-700">
                <Bell className="w-6 h-6" />
                {adminInfo.notifications > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                    {adminInfo.notifications}
                  </span>
                )}
              </button>
              <div className="flex items-center space-x-3">
                <img
                  src={adminInfo.avatar}
                  alt={adminInfo.name}
                  className="w-8 h-8 rounded-full"
                />
                {isSidebarOpen && (
                  <span className="text-sm font-medium">{adminInfo.name}</span>
                )}
              </div>
            </div>
          </div>
        </header>

        <main className="p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;