import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const apiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(
  (config) => {
    console.log('=== Request Interceptor ===');
    const { token, isAuthenticated } = useAuthStore.getState();
    console.log('Auth State:', { token: !!token, isAuthenticated });
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Added token to headers');
    }
    return config;
  },
  (error) => {
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('=== Response Error Interceptor ===');
    console.error('Response Error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    
    if (error.response?.status === 401) {
      console.log('Unauthorized, logging out');
      useAuthStore.getState().logout();
    }
    return Promise.reject(error);
  }
);

export { apiClient };