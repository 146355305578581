import React from 'react';
import { DollarSign, TrendingUp, Users, Calendar } from 'lucide-react';

const VenueAnalytics = () => {
  const stats = [
    {
      title: '今日營收',
      value: 'NT$15,600',
      change: '+15.3%',
      icon: DollarSign,
      trend: 'up',
    },
    {
      title: '本月營收',
      value: 'NT$458,000',
      change: '+12.5%',
      icon: TrendingUp,
      trend: 'up',
    },
    {
      title: '活躍攤販',
      value: '48',
      change: '+8.2%',
      icon: Users,
      trend: 'up',
    },
    {
      title: '預約率',
      value: '86%',
      change: '+5.4%',
      icon: Calendar,
      trend: 'up',
    },
  ];

  const revenueData = [
    { name: '固定租金（7天以上）', amount: 45000 },
    { name: '短期租金（7天以下）', amount: 12000 },
    { name: '臨時租金（單日）', amount: 8000 },
    { name: '設施使用費', amount: 5000 },
  ];

  const usageData = [
    { type: '長期使用 (7天以上)', count: 35 },
    { type: '短期使用 (3-7天)', count: 12 },
    { type: '臨時使用 (1-2天)', count: 8 },
    { type: '單日使用', count: 5 },
  ];

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold">營運分析</h2>
        <p className="text-sm text-gray-500 mt-1">
          每個攤位收費 NT$100/天，依攤位數量及使用天數計費
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.title}
            className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center justify-between mb-4">
              <div
                className={`p-3 rounded-full ${
                  stat.trend === 'up'
                    ? 'bg-green-100 text-green-600'
                    : 'bg-red-100 text-red-600'
                }`}
              >
                <stat.icon className="w-6 h-6" />
              </div>
              <span
                className={`text-sm font-medium ${
                  stat.trend === 'up' ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {stat.change}
              </span>
            </div>
            <h3 className="text-gray-500 text-sm font-medium">{stat.title}</h3>
            <p className="text-2xl font-semibold mt-1">{stat.value}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* 收入分析 */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="font-semibold mb-4">收入分析</h3>
          <div className="space-y-4">
            {revenueData.map((item) => (
              <div key={item.name} className="flex items-center">
                <div className="flex-1">
                  <div className="text-sm font-medium">{item.name}</div>
                  <div className="mt-1 relative pt-1">
                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                      <div
                        style={{
                          width: `${(item.amount / 70000) * 100}%`,
                        }}
                        className="bg-orange-500 rounded"
                      ></div>
                    </div>
                  </div>
                </div>
                <span className="ml-4 text-sm font-medium">
                  NT${item.amount.toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* 使用分析 */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="font-semibold mb-4">使用分析</h3>
          <div className="space-y-4">
            {usageData.map((item) => (
              <div key={item.type} className="flex items-center">
                <div className="flex-1">
                  <div className="text-sm font-medium">{item.type}</div>
                  <div className="mt-1 relative pt-1">
                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                      <div
                        style={{
                          width: `${(item.count / 60) * 100}%`,
                        }}
                        className="bg-orange-500 rounded"
                      ></div>
                    </div>
                  </div>
                </div>
                <span className="ml-4 text-sm font-medium">
                  {item.count} 個攤位
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueAnalytics;