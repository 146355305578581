import React, { useState } from 'react';
import { User, Phone, Mail, Building, CreditCard, Landmark } from 'lucide-react';
import useVenueStore from '../../stores/venueStore';

interface VenueSettingsProps {
  venue: any;
}

const VenueSettings: React.FC<VenueSettingsProps> = ({ venue }) => {
  const updateVenue = useVenueStore((state) => state.updateVenue);
  


  const [settings, setSettings] = useState({
    name: venue?.name || '',
    address: venue?.address || '',
    description: venue?.description || '',
    totalSpots: venue?.totalSpots || 0,
    availableSpots: venue?.availableSpots || 0,
    dailyFee: venue?.dailyFee || 0,
    bookingOpenDays: venue?.bookingOpenDays || 1,
    minBookingDays: venue?.minBookingDays || 1,
    maxDaysPerVendor: venue?.maxDaysPerVendor || 7,
    maxBookingDays: venue?.maxBookingDays || 30,
    bookingOpenTime: venue?.bookingOpenTime || '09:00',
    timeSlots: venue?.timeSlots || [],
    facilities: venue?.facilities || [],
    operatingDays: venue?.operatingDays || [],
    images: venue?.images || [],
    owner: {
      name: venue?.owner?.name || '',
      phone: venue?.owner?.phone || '',
      email: venue?.owner?.email || '',
      taxId: venue?.owner?.taxId || '',
      address: venue?.owner?.address || '',
      bankAccount: venue?.owner?.bankAccount || '',
      bankName: venue?.owner?.bankName || '',
      bankBranch: venue?.owner?.bankBranch || ''
    }
  });
  if (!venue) {
    return <div>載入中...</div>;
  }
  const handleSave = () => {
    updateVenue(venue.id, settings);
  };

  return (
    <div className="space-y-6">
      {/* 場地業者資料 */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center space-x-2 mb-4">
          <User className="w-5 h-5 text-gray-500" />
          <h3 className="font-medium">場地業者資料</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">負責人姓名</label>
            <input
              type="text"
              value={settings.owner.name}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, name: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">聯絡電話</label>
            <input
              type="tel"
              value={settings.owner.phone}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, phone: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">電子郵件</label>
            <input
              type="email"
              value={settings.owner.email}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, email: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">統一編號</label>
            <input
              type="text"
              value={settings.owner.taxId}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, taxId: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">地址</label>
          <input
            type="text"
            value={settings.owner.address}
            onChange={(e) => setSettings({
              ...settings,
              owner: { ...settings.owner, address: e.target.value }
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">銀行帳號</label>
            <input
              type="text"
              value={settings.owner.bankAccount}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, bankAccount: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">銀行名稱</label>
            <input
              type="text"
              value={settings.owner.bankName}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, bankName: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">分行名稱</label>
            <input
              type="text"
              value={settings.owner.bankBranch}
              onChange={(e) => setSettings({
                ...settings,
                owner: { ...settings.owner, bankBranch: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
        >
          儲存設定
        </button>
      </div>
    </div>
  );
};

export default VenueSettings;