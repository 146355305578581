import React from 'react';
import { Clock, MapPin, ShoppingBag, AlertTriangle } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';
import OrderList from './OrderList';
import OperationStatus from './OperationStatus';

const VendorHome = () => {
  const schedules = useVendorStore((state) => state.schedules);
  const orders = useVendorStore((state) => state.orders);
  const operationStatuses = useVendorStore((state) => state.operationStatuses);

  // 獲取今日排程
  const today = new Date().toISOString().split('T')[0];
  const todaySchedule = schedules.find(schedule => schedule.date === today);

  // 獲取今日營業狀態
  const todayStatus = operationStatuses.find(
    status => status.date === today
  );

  // 計算待處理訂單數量
  const pendingOrders = orders.filter(order => order.status === 'pending').length;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* 今日營業資訊 */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">今日營業資訊</h3>
          {todaySchedule ? (
            <div className="space-y-4">
              <div className="flex items-center text-gray-600">
                <MapPin className="w-5 h-5 mr-2" />
                <div>
                  <p className="font-medium">{todaySchedule.venueName}</p>
                  <p className="text-sm">攤位 {todaySchedule.spotNumber}</p>
                </div>
              </div>
              <div className="flex items-center text-gray-600">
                <Clock className="w-5 h-5 mr-2" />
                <span>{todaySchedule.timeSlot}</span>
              </div>
              <div className={`flex items-center ${todayStatus?.status === 'open' ? 'text-green-600' : 'text-gray-600'}`}>
                <div className="w-2 h-2 rounded-full mr-2 bg-current" />
                <span>{todayStatus?.status === 'open' ? '營業中' : '未營業'}</span>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">今日無排程</p>
          )}
        </div>

        {/* 待處理訂單 */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">待處理訂單</h3>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <ShoppingBag className="w-8 h-8 text-orange-600" />
              <div className="ml-3">
                <p className="text-2xl font-semibold">{pendingOrders}</p>
                <p className="text-sm text-gray-500">筆訂單待處理</p>
              </div>
            </div>
          </div>
        </div>

        {/* 問題回報 */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">問題回報</h3>
          {todayStatus?.status === 'reported' ? (
            <div className="flex items-center text-yellow-600">
              <AlertTriangle className="w-5 h-5 mr-2" />
              <span>已回報問題</span>
            </div>
          ) : (
            <p className="text-gray-500">今日無問題回報</p>
          )}
        </div>
      </div>

      {/* 營業狀態管理 */}
      {todaySchedule && (
        <OperationStatus schedule={todaySchedule} />
      )}

      {/* 訂單列表 */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">今日訂單</h3>
          <OrderList />
        </div>
      </div>
    </div>
  );
};

export default VendorHome;