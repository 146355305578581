import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../stores/authStore';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, user, logout } = useAuthStore();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getDashboardLink = () => {
    switch (user?.role) {
      case 'vendor':
        return '/vendor';
      case 'venue':
        return '/venue';
      case 'admin':
        return '/admin';
      default:
        return '/';
    }
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-orange-600">天知道</Link>
          </div>

          {/* 桌面版選單 */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-gray-700 hover:text-orange-600">首頁</Link>
            <Link to="/search" className="text-gray-700 hover:text-orange-600">搜尋餐車</Link>
            <Link to="/partner" className="text-gray-700 hover:text-orange-600">成為夥伴</Link>
            <Link to="/about" className="text-gray-700 hover:text-orange-600">關於我們</Link>
            
            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <Link 
                  to={getDashboardLink()}
                  className="text-gray-700 hover:text-orange-600"
                >
                  {user.name}
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700"
                >
                  登出
                </button>
              </div>
            ) : (
              <Link 
                to="/login" 
                className="bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700"
              >
                登入/註冊
              </Link>
            )}
          </div>

          {/* 手機版選單按鈕 */}
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* 手機版選單內容 */}
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link to="/" className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded">首頁</Link>
              <Link to="/search" className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded">搜尋餐車</Link>
              <Link to="/partner" className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded">成為夥伴</Link>
              <Link to="/about" className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded">關於我們</Link>
              
              {isAuthenticated ? (
                <>
                  <Link 
                    to={getDashboardLink()}
                    className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded"
                  >
                    {user.name}
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-3 py-2 text-gray-700 hover:bg-orange-100 rounded"
                  >
                    登出
                  </button>
                </>
              ) : (
                <Link 
                  to="/login"
                  className="block px-3 py-2 text-gray-700 hover:bg-orange-100 rounded"
                >
                  登入/註冊
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;