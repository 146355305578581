import { create } from 'zustand';

interface OperationStatus {
  id: string;
  scheduleId: number;
  date: string;
  status: 'open' | 'closed' | 'reported';
  openTime?: string;
  closeTime?: string;
  photos: string[];
  reportContent?: string;
  reportPhotos?: string[];
  createdAt: string;
}

interface MenuItem {
  id: number;
  name: string;
  price: number;
  description: string;
  category: string;
  image: string;
  available: boolean;
  preOrder?: boolean;
  preOrderDeadlineTime?: string;
  preOrderLimit?: number;
}

interface Order {
  id: string;
  customer: string;
  items: string[];
  total: number;
  status: 'pending' | 'preparing' | 'completed' | 'cancelled';
  time: string;
}

interface Schedule {
  id: number;
  venueId: number;
  venueName: string;
  address: string;
  date: string;
  timeSlot: string;
  status: 'confirmed' | 'pending';
  spotNumber: string;
  pricePerDay: number;
  bookedDays: number;
}

interface VendorStore {
  menuItems: MenuItem[];
  orders: Order[];
  schedules: Schedule[];
  operationStatuses: OperationStatus[];
  addMenuItem: (item: Omit<MenuItem, 'id'>) => void;
  updateMenuItem: (id: number, updates: Partial<MenuItem>) => void;
  deleteMenuItem: (id: number) => void;
  toggleItemAvailability: (id: number) => void;
  addOrder: (order: Omit<Order, 'id'>) => void;
  updateOrderStatus: (id: string, status: Order['status']) => void;
  addSchedule: (schedule: Omit<Schedule, 'id'>) => void;
  updateSchedule: (id: number, updates: Partial<Schedule>) => void;
  cancelSchedule: (id: number) => void;
  startOperation: (scheduleId: number) => void;
  endOperation: (scheduleId: number) => void;
  reportIssue: (scheduleId: number, content: string, photos: string[]) => void;
  addOperationPhotos: (statusId: string, photos: string[]) => void;
}

const useVendorStore = create<VendorStore>((set) => ({
  menuItems: [
    {
      id: 1,
      name: '招牌滷肉飯',
      price: 60,
      description: '使用特製滷汁醬料，搭配油亮軟嫩的滷肉',
      category: '主食',
      image: 'https://images.unsplash.com/photo-1617622141573-2f54dc392cdf?auto=format&fit=crop&q=80',
      available: true,
      preOrder: true,
      preOrderDeadlineTime: '13:00',
      preOrderLimit: 50,
    },
    {
      id: 2,
      name: '排骨飯',
      price: 80,
      description: '香酥多汁的排骨，搭配特製醬料',
      category: '主食',
      image: 'https://images.unsplash.com/photo-1574071318508-1cdbab80d002?auto=format&fit=crop&q=80',
      available: true,
    },
  ],
  orders: [
    {
      id: 'ORD001',
      customer: '陳小明',
      items: ['招牌滷肉飯', '滷蛋'],
      total: 75,
      status: 'pending',
      time: '10:30',
    },
  ],
  schedules: [
    {
      id: 1,
      venueId: 1,
      venueName: '信義商圈',
      address: '台北市信義區松仁路100號',
      date: '2024-03-20',
      timeSlot: '11:00 - 20:00',
      status: 'confirmed',
      spotNumber: 'A-1',
      pricePerDay: 100,
      bookedDays: 7,
    },
  ],
  operationStatuses: [],

  addMenuItem: (item) =>
    set((state) => ({
      menuItems: [...state.menuItems, { ...item, id: Date.now() }],
    })),

  updateMenuItem: (id, updates) =>
    set((state) => ({
      menuItems: state.menuItems.map((item) =>
        item.id === id ? { ...item, ...updates } : item
      ),
    })),

  deleteMenuItem: (id) =>
    set((state) => ({
      menuItems: state.menuItems.filter((item) => item.id !== id),
    })),

  toggleItemAvailability: (id) =>
    set((state) => ({
      menuItems: state.menuItems.map((item) =>
        item.id === id ? { ...item, available: !item.available } : item
      ),
    })),

  addOrder: (order) =>
    set((state) => ({
      orders: [
        ...state.orders,
        { ...order, id: `ORD${state.orders.length + 1}`.padStart(6, '0') },
      ],
    })),

  updateOrderStatus: (id, status) =>
    set((state) => ({
      orders: state.orders.map((order) =>
        order.id === id ? { ...order, status } : order
      ),
    })),

  addSchedule: (schedule) =>
    set((state) => ({
      schedules: [...state.schedules, { ...schedule, id: Date.now() }],
    })),

  updateSchedule: (id, updates) =>
    set((state) => ({
      schedules: state.schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, ...updates } : schedule
      ),
    })),

  cancelSchedule: (id) =>
    set((state) => ({
      schedules: state.schedules.filter((schedule) => schedule.id !== id),
    })),

  startOperation: (scheduleId) => {
    const statusId = `op-${Date.now()}`;
    set((state) => ({
      operationStatuses: [
        ...state.operationStatuses,
        {
          id: statusId,
          scheduleId,
          date: new Date().toISOString().split('T')[0],
          status: 'open',
          openTime: new Date().toLocaleTimeString(),
          photos: [],
          createdAt: new Date().toISOString(),
        },
      ],
    }));
    
    // 3天後自動刪除照片
    setTimeout(() => {
      set((state) => ({
        operationStatuses: state.operationStatuses.map(status =>
          status.id === statusId ? { ...status, photos: [] } : status
        ),
      }));
    }, 3 * 24 * 60 * 60 * 1000);
    
    return statusId;
  },

  endOperation: (scheduleId) =>
    set((state) => ({
      operationStatuses: state.operationStatuses.map((status) =>
        status.scheduleId === scheduleId && status.status === 'open'
          ? { ...status, status: 'closed', closeTime: new Date().toLocaleTimeString() }
          : status
      ),
    })),

  reportIssue: (scheduleId, content, photos) => {
    const statusId = `op-${Date.now()}`;
    set((state) => ({
      operationStatuses: [
        ...state.operationStatuses,
        {
          id: statusId,
          scheduleId,
          date: new Date().toISOString().split('T')[0],
          status: 'reported',
          reportContent: content,
          reportPhotos: photos,
          photos: [],
          createdAt: new Date().toISOString(),
        },
      ],
    }));
    
    // 3天後自動刪除照片
    setTimeout(() => {
      set((state) => ({
        operationStatuses: state.operationStatuses.map(status =>
          status.id === statusId ? { ...status, reportPhotos: [] } : status
        ),
      }));
    }, 3 * 24 * 60 * 60 * 1000);
  },

  addOperationPhotos: (statusId, photos) =>
    set((state) => ({
      operationStatuses: state.operationStatuses.map((status) =>
        status.id === statusId
          ? { ...status, photos: [...status.photos, ...photos] }
          : status
      ),
    })),
}));

export default useVendorStore;