import React, { useState } from 'react';
import { Search, MapPin, Filter, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import useVendorStore from '../stores/vendorStore';

const HomePage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArea, setSelectedArea] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const vendors = useVendorStore((state) => state.menuItems);
  const operationStatuses = useVendorStore((state) => state.operationStatuses);

  // 取得目前營業中的餐車
  const activeVendors = operationStatuses
    .filter(status => status.status === 'open')
    .map(status => {
      const schedule = vendors.find(v => v.id === status.scheduleId);
      return schedule;
    })
    .filter(Boolean);

  const areas = ['all', '信義區', '中山區', '大安區', '松山區'];
  const categories = ['all', '台式料理', '日式料理', '韓式料理', '美式料理'];

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[500px] bg-gradient-to-r from-orange-600 to-orange-400">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1565123409695-7b5ef63a2efb?auto=format&fit=crop&q=80"
            alt="美食背景"
            className="w-full h-full object-cover opacity-30"
          />
        </div>
        <div className="relative container mx-auto px-4 h-full flex flex-col items-center justify-center text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            發現您附近的美食餐車
          </h1>
          <p className="text-xl text-white mb-8">
            探索城市中最獨特的美食體驗
          </p>
          
          {/* 搜尋區塊 */}
          <div className="w-full max-w-3xl bg-white rounded-lg shadow-xl p-2">
            <div className="flex flex-col md:flex-row gap-2">
              <div className="flex-1 flex items-center bg-gray-50 rounded-lg px-4 py-2">
                <MapPin className="w-5 h-5 text-gray-400 mr-2" />
                <select
                  value={selectedArea}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  className="w-full bg-transparent focus:outline-none"
                >
                  {areas.map(area => (
                    <option key={area} value={area}>
                      {area === 'all' ? '選擇地區' : area}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex items-center bg-gray-50 rounded-lg px-4 py-2">
                <Filter className="w-5 h-5 text-gray-400 mr-2" />
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full bg-transparent focus:outline-none"
                >
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category === 'all' ? '選擇類型' : category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1 flex items-center bg-gray-50 rounded-lg px-4 py-2">
                <Search className="w-5 h-5 text-gray-400 mr-2" />
                <input
                  type="text"
                  placeholder="搜尋餐車或美食"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-transparent focus:outline-none"
                />
              </div>
              <button className="bg-orange-600 text-white px-8 py-2 rounded-lg hover:bg-orange-700 transition-colors">
                搜尋
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 營業中的餐車 */}
      <div className="container mx-auto px-4 py-16">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-2">營業中的餐車</h2>
          <p className="text-gray-600">立即訂購美食</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {activeVendors.map((vendor: any) => (
            <Link 
              key={vendor.id}
              to={`/truck/${vendor.id}`}
              className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="h-48 overflow-hidden">
                <img
                  src={vendor.image}
                  alt={vendor.name}
                  className="w-full h-full object-cover hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-xl font-semibold">{vendor.name}</h3>
                  <div className="flex items-center">
                    <Star className="w-5 h-5 text-yellow-400 fill-current" />
                    <span className="ml-1 text-gray-600">4.8</span>
                  </div>
                </div>
                <p className="text-gray-500 mb-4">{vendor.description}</p>
                <div className="flex justify-between text-sm">
                  <span className="text-orange-600 font-medium">營業中</span>
                  <span className="text-gray-500">信義區</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* 地圖區塊 */}
      <div className="container mx-auto px-4 py-16">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-2">附近的餐車</h2>
          <p className="text-gray-600">查看您附近的餐車位置</p>
        </div>
        <div className="bg-gray-100 rounded-xl p-4 h-[400px] flex items-center justify-center">
          <p className="text-gray-500">地圖功能即將推出</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;