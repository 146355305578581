import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Calendar, TrendingUp, Menu, Bell, Home, MapPin } from 'lucide-react';

interface VendorLayoutProps {
  children: React.ReactNode;
}

const VendorLayout = ({ children }: VendorLayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();

  const vendorInfo = {
    name: '阿明滷肉飯',
    avatar: 'https://images.unsplash.com/photo-1531263939119-4022c6cf273b?auto=format&fit=crop&q=80',
    notifications: 3,
  };

  const menuItems = [
    { id: 'home', label: '控制台首頁', icon: Home, path: '/vendor' },
    { id: 'menu', label: '菜單管理', icon: Menu, path: '/vendor/menu' },
    { id: 'schedule', label: '排程管理', icon: Calendar, path: '/vendor/schedule' },
    { id: 'spots', label: '攤位申請', icon: MapPin, path: '/vendor/spots' },
    { id: 'analytics', label: '營業分析', icon: TrendingUp, path: '/vendor/analytics' },
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* 側邊欄 */}
      <div className={`bg-white shadow-lg ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300`}>
        <div className="p-4">
          <div className="flex items-center space-x-4">
            <img
              src={vendorInfo.avatar}
              alt={vendorInfo.name}
              className="w-10 h-10 rounded-full"
            />
            {isSidebarOpen && (
              <div className="flex-1">
                <h2 className="font-semibold">{vendorInfo.name}</h2>
                <p className="text-sm text-gray-500">餐車業者</p>
              </div>
            )}
          </div>
        </div>

        <nav className="mt-8">
          {menuItems.map((item) => (
            <Link
              key={item.id}
              to={item.path}
              className={`w-full flex items-center px-4 py-3 ${
                location.pathname === item.path
                  ? 'bg-orange-50 text-orange-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <item.icon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-4">{item.label}</span>}
            </Link>
          ))}
        </nav>
      </div>

      {/* 主要內容區 */}
      <div className="flex-1">
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-6 py-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="text-gray-500 hover:text-gray-700"
            >
              <Menu className="w-6 h-6" />
            </button>

            <div className="flex items-center space-x-4">
              <button className="relative text-gray-500 hover:text-gray-700">
                <Bell className="w-6 h-6" />
                {vendorInfo.notifications > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                    {vendorInfo.notifications}
                  </span>
                )}
              </button>
            </div>
          </div>
        </header>

        <main className="p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default VendorLayout;