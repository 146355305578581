import React from 'react';
import { Star, ThumbsUp } from 'lucide-react';

const ReviewSection = () => {
  const reviews = [
    {
      id: 1,
      user: '陳小明',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80',
      rating: 5,
      date: '2024-03-15',
      content: '滷肉飯真的超級好吃！肉質軟嫩，醬汁香濃不死鹹，配菜也很新鮮。',
      likes: 12,
      images: [
        'https://images.unsplash.com/photo-1617622141573-2f54dc392cdf?auto=format&fit=crop&q=80',
      ],
    },
    {
      id: 2,
      user: '林小華',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80',
      rating: 4,
      date: '2024-03-14',
      content: '排骨飯份量十足，肉質也很好，就是等待時間稍微久了一點。',
      likes: 8,
      images: [],
    },
  ];

  return (
    <div className="space-y-6">
      {/* 評分統計 */}
      <div className="bg-orange-50 p-6 rounded-lg mb-8">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-3xl font-bold">4.8</div>
            <div className="flex items-center mt-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <Star
                  key={star}
                  className="w-5 h-5 text-yellow-400 fill-current"
                />
              ))}
            </div>
            <div className="text-sm text-gray-500 mt-1">128 則評價</div>
          </div>
          <button className="bg-orange-600 text-white px-6 py-2 rounded-lg hover:bg-orange-700 transition-colors">
            撰寫評價
          </button>
        </div>
      </div>

      {/* 評價列表 */}
      <div className="space-y-6">
        {reviews.map((review) => (
          <div key={review.id} className="border-b pb-6">
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <img
                  src={review.avatar}
                  alt={review.user}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <div className="font-semibold">{review.user}</div>
                  <div className="flex items-center mt-1">
                    {[...Array(review.rating)].map((_, i) => (
                      <Star
                        key={i}
                        className="w-4 h-4 text-yellow-400 fill-current"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <span className="text-gray-500 text-sm">{review.date}</span>
            </div>
            <p className="mt-4 text-gray-600">{review.content}</p>
            {review.images.length > 0 && (
              <div className="mt-4 flex gap-2">
                {review.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Review ${index + 1}`}
                    className="w-24 h-24 rounded-lg object-cover"
                  />
                ))}
              </div>
            )}
            <button className="mt-4 flex items-center text-gray-500 hover:text-gray-700">
              <ThumbsUp className="w-4 h-4 mr-1" />
              <span className="text-sm">有幫助 ({review.likes})</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewSection;