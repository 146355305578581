import React, { useState } from 'react';
import { Star, Clock, MapPin, Phone, Calendar, ChefHat, Heart } from 'lucide-react';
import MenuSection from './MenuSection';
import ReviewSection from './ReviewSection';

const TruckDetail = () => {
  const [activeTab, setActiveTab] = useState('menu');
  
  const truckInfo = {
    name: '阿明滷肉飯',
    rating: 4.8,
    reviews: 128,
    category: '台式料理',
    location: '台北市中山區林森北路123號',
    phone: '0912-345-678',
    hours: '11:00 - 20:00',
    image: 'https://images.unsplash.com/photo-1531263939119-4022c6cf273b?auto=format&fit=crop&q=80',
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* 餐車主圖和基本信息 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="rounded-xl overflow-hidden h-[400px]">
          <img
            src={truckInfo.image}
            alt={truckInfo.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start">
              <h1 className="text-3xl font-bold mb-4">{truckInfo.name}</h1>
              <button className="p-2 rounded-full hover:bg-gray-100">
                <Heart className="w-6 h-6 text-gray-500" />
              </button>
            </div>
            <div className="flex items-center mb-4">
              <Star className="w-5 h-5 text-yellow-400 fill-current" />
              <span className="ml-1 font-semibold">{truckInfo.rating}</span>
              <span className="ml-2 text-gray-500">({truckInfo.reviews} 則評價)</span>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-gray-600">
                <ChefHat className="w-5 h-5 mr-2" />
                <span>{truckInfo.category}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <MapPin className="w-5 h-5 mr-2" />
                <span>{truckInfo.location}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Phone className="w-5 h-5 mr-2" />
                <span>{truckInfo.phone}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Clock className="w-5 h-5 mr-2" />
                <span>{truckInfo.hours}</span>
              </div>
            </div>
          </div>
          <button className="mt-6 bg-orange-600 text-white py-3 px-6 rounded-lg hover:bg-orange-700 transition-colors">
            立即預約
          </button>
        </div>
      </div>

      {/* 分頁選項 */}
      <div className="border-b mb-8">
        <div className="flex space-x-8">
          <button
            className={`py-4 px-2 -mb-px ${
              activeTab === 'menu'
                ? 'border-b-2 border-orange-600 text-orange-600'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('menu')}
          >
            菜單
          </button>
          <button
            className={`py-4 px-2 -mb-px ${
              activeTab === 'reviews'
                ? 'border-b-2 border-orange-600 text-orange-600'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('reviews')}
          >
            評價
          </button>
        </div>
      </div>

      {/* 分頁內容 */}
      {activeTab === 'menu' ? <MenuSection /> : <ReviewSection />}
    </div>
  );
};

export default TruckDetail;