import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Edit2, Check, X, Trash2 } from 'lucide-react';

interface DraggableSpotProps {
  spot: {
    id: string;
    left: number;
    top: number;
    width: number;
    height: number;
    styleId: string;
    notes?: string;
    customLabel?: string;
    venueId: string;
  };
  styles: Record<string, {
    backgroundColor: string;
    borderColor: string;
    label: string;
  }>;
  onSelect: (id: string) => void;
  spotNumber: number;
  updateSpot: (id: string, updates: any) => void;
  removeSpot: (id: string) => void;
}

const DraggableSpot: React.FC<DraggableSpotProps> = ({ 
  spot, 
  styles, 
  onSelect, 
  spotNumber,
  updateSpot,
  removeSpot 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(spot.notes || '');
  const [customLabel, setCustomLabel] = useState(spot.customLabel || '');
  const [isResizing, setIsResizing] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'spot',
    item: () => ({
      id: spot.id,
      left: spot.left,
      top: spot.top,
      initialLeft: spot.left,
      initialTop: spot.top,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [spot.id, spot.left, spot.top]);

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
    onSelect(spot.id);
  };

  const handleSave = () => {
    updateSpot(spot.id, {
      notes,
      customLabel: spot.styleId === 'custom' ? customLabel : undefined
    });
    setIsEditing(false);
  };

  const pixelsToMeters = (pixels: number) => (pixels / 20).toFixed(1);

  const handleResize = (e: React.MouseEvent, direction: string) => {
    e.preventDefault();
    e.stopPropagation();
    
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = spot.width;
    const startHeight = spot.height;
    
    const handleMouseMove = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;
      
      let newWidth = startWidth;
      let newHeight = startHeight;
      
      if (direction.includes('e')) {
        newWidth = Math.max(60, startWidth + deltaX);
      }
      if (direction.includes('s')) {
        newHeight = Math.max(60, startHeight + deltaY);
      }
      
      newWidth = Math.round(newWidth / 10) * 10;
      newHeight = Math.round(newHeight / 10) * 10;
      
      updateSpot(spot.id, { width: newWidth, height: newHeight });
    };
    
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      setIsResizing(false);
    };
    
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    setIsResizing(true);
  };

  return (
    <div
      ref={drag}
      style={{
        position: 'absolute',
        left: `${spot.left}px`,
        top: `${spot.top}px`,
        width: `${spot.width}px`,
        height: `${spot.height}px`,
        backgroundColor: styles[spot.styleId].backgroundColor,
        border: `2px solid ${styles[spot.styleId].borderColor}`,
        cursor: isResizing ? 'resize' : 'move',
        opacity: isDragging ? 0.5 : 1,
        zIndex: isDragging ? 1000 : 1,
      }}
      className="rounded-lg shadow-sm hover:shadow-md transition-shadow"
    >
      <div className="p-2 text-sm flex flex-col h-full">
        <div className="flex justify-between items-center">
          {spot.styleId !== 'custom' && <span>#{spotNumber}</span>}
          <div className="flex space-x-1">
            {isEditing ? (
              <>
                <button onClick={handleSave}>
                  <Check className="w-4 h-4 text-green-600" />
                </button>
                <button onClick={() => setIsEditing(false)}>
                  <X className="w-4 h-4 text-red-600" />
                </button>
              </>
            ) : (
              <>
                <button onClick={handleEditClick}>
                  <Edit2 className="w-4 h-4 text-gray-600" />
                </button>
                <button 
                  onClick={() => removeSpot(spot.id)}
                  className="p-1 rounded-full hover:bg-red-100"
                >
                  <Trash2 className="w-4 h-4 text-red-600" />
                </button>
              </>
            )}
          </div>
        </div>

        <div className="text-gray-500 text-xs mt-1">
          {pixelsToMeters(spot.width)}m × {pixelsToMeters(spot.height)}m
        </div>

        {spot.styleId === 'custom' && (
          isEditing ? (
            <input
              type="text"
              value={customLabel}
              onChange={(e) => setCustomLabel(e.target.value)}
              placeholder="輸入標示名稱..."
              className="mt-2 w-full border rounded px-2 py-1 text-sm"
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            customLabel && (
              <div className="mt-2 text-gray-600 text-sm font-medium">
                {customLabel}
              </div>
            )
          )
        )}

        {isEditing ? (
          <input
            type="text"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="備註..."
            className="mt-2 w-full border rounded px-2 py-1 text-sm"
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          spot.notes && (
            <div className="mt-2 text-gray-600 text-sm overflow-hidden">
              {spot.notes}
            </div>
          )
        )}
      </div>

      <div
        className="absolute bottom-0 right-0 w-4 h-4 bg-white border-2 border-gray-400 rounded-bl cursor-se-resize"
        onMouseDown={(e) => handleResize(e, 'se')}
      />
    </div>
  );
};

export default DraggableSpot; 