import React, { useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { User, ShoppingBag, Heart, Settings, LogOut } from 'lucide-react';
import useAuthStore from '../stores/authStore';

const CustomerDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { logout } = useAuthStore();

  const menuItems = [
    { id: 'profile', label: '個人資料', icon: User, path: '/customer/profile' },
    { id: 'orders', label: '我的訂單', icon: ShoppingBag, path: '/customer/orders' },
    { id: 'favorites', label: '收藏餐車', icon: Heart, path: '/customer/favorites' },
    { id: 'settings', label: '帳號設定', icon: Settings, path: '/customer/settings' },
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* 側邊欄 */}
      <div className={`bg-white shadow-lg ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300`}>
        <div className="p-4">
          <div className="flex items-center space-x-4">
            <div className="bg-orange-100 p-2 rounded-lg">
              <User className="w-8 h-8 text-orange-600" />
            </div>
            {isSidebarOpen && (
              <div className="flex-1">
                <h2 className="font-semibold">顧客中心</h2>
                <p className="text-sm text-gray-500">管理您的帳戶</p>
              </div>
            )}
          </div>
        </div>

        <nav className="mt-8">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => navigate(item.path)}
              className={`w-full flex items-center px-4 py-3 ${
                location.pathname === item.path
                  ? 'bg-orange-50 text-orange-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <item.icon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-4">{item.label}</span>}
            </button>
          ))}
          
          <button
            onClick={handleLogout}
            className="w-full flex items-center px-4 py-3 text-red-600 hover:bg-red-50 mt-4"
          >
            <LogOut className="w-5 h-5" />
            {isSidebarOpen && <span className="ml-4">登出</span>}
          </button>
        </nav>
      </div>

      {/* 主要內容區 */}
      <div className="flex-1">
        <main className="p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default CustomerDashboard; 