import React, { useState } from 'react';
import { X } from 'lucide-react';
import { format, addMonths, startOfMonth } from 'date-fns';

interface MonthlyBookingModalProps {
  onClose: () => void;
  onConfirm: (month: string) => void;
}

const MonthlyBookingModal: React.FC<MonthlyBookingModalProps> = ({ onClose, onConfirm }) => {
  // 設定預設月份為下個月
  const defaultMonth = format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM');
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedMonth) {
      onConfirm(selectedMonth);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">開啟場地預訂</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              選擇月份
            </label>
            <input
              type="month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              min={format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM')}
              max={format(startOfMonth(addMonths(new Date(), 12)), 'yyyy-MM')}
              required
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              取消
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
            >
              確認開啟
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MonthlyBookingModal; 