import React, { useState } from "react";
import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { X } from "lucide-react";
import { format, addMonths, subMonths, startOfMonth } from "date-fns";
import useVenueStore from "../../stores/venueStore";

interface VenueCalendarProps {
  venue: any;
  onClose: () => void;
}

const VenueCalendar: React.FC<VenueCalendarProps> = ({ venue, onClose }) => {
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const monthlyBookings = useVenueStore((state) => state.monthlyBookings);

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const monthStr = format(currentMonth, "yyyy-MM");
  const booking = monthlyBookings.find(
    (b: { venueId: any; month: string }) =>
      b.venueId === venue.id && b.month === monthStr
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="space-y-6 bg-white w-full max-w-6xl rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePrevMonth}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <h3 className="text-xl font-semibold">
              {format(currentMonth, "yyyy年MM月")}
            </h3>
            <button
              onClick={handleNextMonth}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
          <div className="px-3">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-2">
              <CalendarIcon className="w-5 h-5 text-gray-500" />
              <span className="font-medium">預約狀態</span>
            </div>
            {booking ? (
              <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                已開放預訂
              </span>
            ) : (
              <span className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                未開放
              </span>
            )}
          </div>

          {booking && (
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-500">可預訂攤位</p>
                <p className="text-2xl font-semibold">
                  {booking.availableSpots} / {venue.totalSpots}
                </p>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-orange-500 h-2 rounded-full"
                  style={{
                    width: `${
                      (booking.availableSpots / venue.totalSpots) * 100
                    }%`,
                  }}
                ></div>
              </div>

              <div className="pt-4 border-t">
                <h4 className="font-medium mb-2">預訂開放時間</h4>
                <p className="text-sm text-gray-600">
                  每月{venue.bookingOpenDay}號 {venue.bookingOpenTime}
                </p>
              </div>
            </div>
          )}

          {!booking && (
            <div className="text-center py-8">
              <p className="text-gray-500">此月份尚未開放預訂</p>
              <button className="mt-4 px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700" onClick={onClose}>
                開放預訂
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VenueCalendar;
