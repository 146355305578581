import React from 'react';
import { TrendingUp, Users, ShoppingBag, DollarSign } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';

const Analytics = () => {
  const orders = useVendorStore((state) => state.orders);
  const menuItems = useVendorStore((state) => state.menuItems);

  // 計算統計數據
  const totalRevenue = orders.reduce((sum, order) => sum + order.total, 0);
  const orderCount = orders.length;
  const averageOrderValue = orderCount > 0 ? totalRevenue / orderCount : 0;
  const repeatCustomerRate = 32; // 模擬數據

  const stats = [
    {
      title: '本月營收',
      value: `NT$${totalRevenue.toLocaleString()}`,
      change: '+12.5%',
      icon: DollarSign,
      trend: 'up',
    },
    {
      title: '訂單數量',
      value: orderCount.toString(),
      change: '+8.2%',
      icon: ShoppingBag,
      trend: 'up',
    },
    {
      title: '平均客單價',
      value: `NT$${averageOrderValue.toFixed(0)}`,
      change: '+4.3%',
      icon: Users,
      trend: 'up',
    },
    {
      title: '回購率',
      value: `${repeatCustomerRate}%`,
      change: '+2.4%',
      icon: TrendingUp,
      trend: 'up',
    },
  ];

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold">營業分析</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.title}
            className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center justify-between mb-4">
              <div
                className={`p-3 rounded-full ${
                  stat.trend === 'up'
                    ? 'bg-green-100 text-green-600'
                    : 'bg-red-100 text-red-600'
                }`}
              >
                <stat.icon className="w-6 h-6" />
              </div>
              <span
                className={`text-sm font-medium ${
                  stat.trend === 'up' ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {stat.change}
              </span>
            </div>
            <h3 className="text-gray-500 text-sm font-medium">{stat.title}</h3>
            <p className="text-2xl font-semibold mt-1">{stat.value}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="font-semibold mb-4">熱門品項排行</h3>
          <div className="space-y-4">
            {menuItems.slice(0, 4).map((item, index) => (
              <div key={item.id} className="flex items-center">
                <span className="w-8 text-gray-500">{index + 1}</span>
                <div className="flex-1">
                  <div className="text-sm font-medium">{item.name}</div>
                  <div className="mt-1 relative pt-1">
                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                      <div
                        style={{
                          width: `${100 - index * 20}%`,
                        }}
                        className="bg-orange-500 rounded"
                      ></div>
                    </div>
                  </div>
                </div>
                <span className="ml-4 text-sm text-gray-600">
                  {100 - index * 20}份
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="font-semibold mb-4">營業時段分析</h3>
          <div className="space-y-4">
            {['11:00-13:00', '13:00-15:00', '15:00-17:00', '17:00-19:00'].map(
              (time, index) => (
                <div key={time} className="flex items-center">
                  <span className="w-24 text-sm text-gray-600">{time}</span>
                  <div className="flex-1">
                    <div className="mt-1 relative pt-1">
                      <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100">
                        <div
                          style={{
                            width: `${85 - index * 15}%`,
                          }}
                          className="bg-orange-500 rounded"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <span className="ml-4 text-sm text-gray-600">
                    {85 - index * 15}筆
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;