import React, { useState, useEffect } from 'react';
import { Plus, MapPin, Zap, Droplet, Trash2, Calendar, Clock, FileText } from 'lucide-react';
import useVenueStore from '../../stores/venueStore';
import VenueForm from './VenueForm';
import VenueDetail from './VenueDetail';
import MonthlyBookingModal from './MonthlyBookingModal';

const weekDayMap: Record<string, string> = {
  'monday': '週一',
  'tuesday': '週二',
  'wednesday': '週三',
  'thursday': '週四',
  'friday': '週五',
  'saturday': '週六',
  'sunday': '週日'
};

const VenueList = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState<any>(null);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedVenueForBooking, setSelectedVenueForBooking] = useState<any>(null);
  const venues = useVenueStore((state) => state.venues);
  const fetchVenues = useVenueStore((state) => state.fetchVenues);
  const deleteVenue = useVenueStore((state) => state.deleteVenue);

  useEffect(() => {
    fetchVenues();
  }, [fetchVenues]);

  const handleDelete = async (venueId: number) => {
    try {
      if (window.confirm('確定要刪除此場地嗎？')) {
        await deleteVenue(venueId);
      }
    } catch (error) {
      console.error('刪除場地失敗:', error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">場地管理</h2>
        <button
          onClick={() => setShowForm(true)}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 flex items-center"
        >
          <Plus className="w-5 h-5 mr-2" />
          新增場地
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {venues.map((venue) => (
          <div
            key={venue.id}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="h-48 overflow-hidden relative">
              <img
                src={venue.image}
                alt={venue.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                <h3 className="text-xl font-semibold text-white">{venue.name}</h3>
                <p className="text-sm text-white/80 flex items-center mt-1">
                  <MapPin className="w-4 h-4 mr-1" />
                  {venue.address}
                </p>
              </div>
            </div>

            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <span className="text-sm text-gray-600">攤位數量</span>
                  <p className="text-lg font-semibold">
                     {venue.totalSpots}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-600">每日收費</span>
                  <p className="text-lg font-semibold text-orange-600">
                    NT$ {(Number(venue.dailyFee) || 0).toLocaleString()}
                  </p>
                </div>
              </div>

              <div className="mb-4 space-y-2">
                <div>
                  <span className="text-sm text-gray-600 flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    營業日
                  </span>
                  <p className="text-sm">
                    {venue.operatingDays
                      .map(day => weekDayMap[day] || day)
                      .join(', ')}
                  </p>
                </div>
                
                <div>
                  <span className="text-sm text-gray-600 flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    營業時段
                  </span>
                  <p className="text-sm">
                    {(venue.timeSlots || []).map(slot => {
                      const start = slot?.startTime || '00:00';
                      const end = slot?.endTime || '00:00';
                      return `${start}-${end}`;
                    }).join(', ')}
                  </p>
                </div>
                
                <div>
                  <span className="text-sm text-gray-600 flex items-center">
                    <FileText className="w-4 h-4 mr-1" />
                    場地介紹
                  </span>
                  <p className="text-sm line-clamp-2">{venue.description}</p>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 mb-4">
                {venue.facilities.map((facility) => (
                  <span
                    key={facility}
                    className="px-2 py-1 bg-gray-100 rounded-full text-xs flex items-center"
                  >
                    {facility.includes('供電') && <Zap className="w-3 h-3 mr-1" />}
                    {facility.includes('供水') && <Droplet className="w-3 h-3 mr-1" />}
                    {facility}
                  </span>
                ))}
              </div>

              <div className="grid grid-cols-3 gap-2">
                <button
                  onClick={() => setSelectedVenue(venue)}
                  className="flex items-center justify-center px-3 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                >
                  管理場地
                </button>
                <button
                  onClick={() => {
                    setSelectedVenueForBooking(venue);
                    setShowBookingModal(true);
                  }}
                  className="flex items-center justify-center px-3 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                >
                  開啟預訂
                </button>
                <button
                  onClick={() => handleDelete(venue.id)}
                  className="flex items-center justify-center px-3 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <VenueForm onClose={() => {setShowForm(false);fetchVenues();}} />
      )}

      {selectedVenue && !showForm && (
        <VenueDetail venue={selectedVenue} onClose={() => {setSelectedVenue(null);fetchVenues();}} />
      )}

      {showBookingModal && selectedVenueForBooking && (
        <MonthlyBookingModal
          onClose={() => {
            setShowBookingModal(false);
            setSelectedVenueForBooking(null);
          }}
          onConfirm={async (month) => {
            try {
              await useVenueStore.getState().openMonthlyBooking(selectedVenueForBooking.id,month);
              alert('預訂已開啟');
              setShowBookingModal(false);
              setSelectedVenueForBooking(null);
            } catch (error: any) {
              alert(error.message || '開啟預訂失敗');
            }
          }}
        />
      )}
    </div>
  );
};

export default VenueList;