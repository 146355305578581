import React, { useState } from 'react';
import { X } from 'lucide-react';
import { format, addMonths, startOfMonth } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import useVenueStore from '../../stores/venueStore';
import { toast } from 'react-hot-toast';

interface MonthlyBookingFormProps {
  onClose: () => void;
}

const MonthlyBookingForm: React.FC<MonthlyBookingFormProps> = ({ onClose }) => {
  const venues = useVenueStore((state) => state.venues);
  const openMonthlyBooking = useVenueStore((state) => state.openMonthlyBooking);
  const fetchMonthlyBookings = useVenueStore((state) => state.fetchMonthlyBookings);

  const defaultMonth = startOfMonth(addMonths(new Date(), 1));
  const [formData, setFormData] = useState({
    venueId: '',
    month: defaultMonth,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const date = addMonths(defaultMonth, i);
    return {
      value: format(date, 'yyyy-MM'),
      label: format(date, 'yyyy年MM月', { locale: zhTW }),
    };
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.venueId) return;

    try {
      setIsSubmitting(true);
      await openMonthlyBooking(
        parseInt(formData.venueId),
        format(formData.month, 'yyyy-MM')
      );
      await fetchMonthlyBookings(0);
      toast.success('成功開放預訂');
      onClose();
    } catch (error: any) {
      toast.error(error.message || '開放預訂失敗');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">開放月份預訂</h3>
          <button onClick={onClose}>
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">選擇場地</label>
            <select
              required
              value={formData.venueId}
              onChange={(e) => setFormData({ ...formData, venueId: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            >
              <option value="">請選擇場地</option>
              {venues.map((venue) => (
                <option key={venue.id} value={venue.id}>
                  {venue.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">選擇月份</label>
            <select
              required
              value={format(formData.month, 'yyyy-MM')}
              onChange={(e) => {
                const [year, month] = e.target.value.split('-');
                const newDate = new Date(parseInt(year), parseInt(month) - 1);
                setFormData({ ...formData, month: startOfMonth(newDate) });
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            >
              {monthOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {formData.venueId && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium mb-2">預訂開放資訊</h4>
              <p className="text-sm text-gray-600">
                開放時間：每月{venues.find(v => v.id === parseInt(formData.venueId))?.bookingOpenDay}號{' '}
                {venues.find(v => v.id === parseInt(formData.venueId))?.bookingOpenTime}
              </p>
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              disabled={isSubmitting}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              取消
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700 disabled:opacity-50 flex items-center"
            >
              {isSubmitting ? '處理中...' : '開放預訂'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MonthlyBookingForm;