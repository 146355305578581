import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('請輸入電子郵件')
    .email('請輸入有效的電子郵件'),
  password: yup
    .string()
    .required('請輸入密碼')
    .min(8, '密碼至少需要8個字元'),
});

export const registerSchema = yup.object().shape({
  name: yup
    .string()
    .required('請輸入姓名')
    .min(2, '姓名至少需要2個字元'),
  email: yup
    .string()
    .required('請輸入電子郵件')
    .email('請輸入有效的電子郵件'),
  phone: yup
    .string()
    .required('請輸入聯絡電話')
    .matches(/^[0-9-]{10,}$/, '請輸入有效的電話號碼'),
  password: yup
    .string()
    .required('請輸入密碼')
    .min(8, '密碼至少需要8個字元')
    .matches(/[A-Z]/, '密碼需要包含至少一個大寫字母')
    .matches(/[a-z]/, '密碼需要包含至少一個小寫字母')
    .matches(/[0-9]/, '密碼需要包含至少一個數字'),
  confirmPassword: yup
    .string()
    .required('請再次輸入密碼')
    .oneOf([yup.ref('password')], '兩次輸入的密碼不相符'),
  role: yup
    .string()
    .required('請選擇註冊身份')
    .oneOf(['customer', 'vendor', 'venue'], '請選擇有效的註冊身份'),
  businessName: yup.string().when('role', {
    is: (role: string) => role === 'vendor' || role === 'venue',
    then: () => yup.string().required('請輸入商家名稱'),
  }),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required('請輸入電子郵件')
    .email('請輸入有效的電子郵件'),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('請輸入新密碼')
    .min(8, '密碼至少需要8個字元')
    .matches(/[A-Z]/, '密碼需要包含至少一個大寫字母')
    .matches(/[a-z]/, '密碼需要包含至少一個小寫字母')
    .matches(/[0-9]/, '密碼需要包含至少一個數字'),
  confirmPassword: yup
    .string()
    .required('請再次輸入新密碼')
    .oneOf([yup.ref('password')], '兩次輸入的密碼不相符'),
});