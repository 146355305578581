import React, { useState } from 'react';
import { Camera, AlertTriangle, Upload, X } from 'lucide-react';
import useVendorStore from '../../stores/vendorStore';

interface OperationStatusProps {
  schedule: any;
}

const OperationStatus: React.FC<OperationStatusProps> = ({ schedule }) => {
  const [showReportForm, setShowReportForm] = useState(false);
  const [reportContent, setReportContent] = useState('');
  const [reportPhotos, setReportPhotos] = useState<string[]>([]);
  
  const {
    operationStatuses,
    startOperation,
    endOperation,
    reportIssue,
    addOperationPhotos,
  } = useVendorStore();

  const currentStatus = operationStatuses.find(
    (status) =>
      status.scheduleId === schedule.id &&
      status.date === new Date().toISOString().split('T')[0]
  );

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && currentStatus) {
      const newPhotos = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      addOperationPhotos(currentStatus.id, newPhotos);
    }
  };

  const handleReportPhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newPhotos = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setReportPhotos([...reportPhotos, ...newPhotos]);
    }
  };

  const handleSubmitReport = () => {
    if (reportContent.trim()) {
      reportIssue(schedule.id, reportContent, reportPhotos);
      setShowReportForm(false);
      setReportContent('');
      setReportPhotos([]);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-semibold mb-4">營業狀態</h3>

      {!currentStatus ? (
        <button
          onClick={() => startOperation(schedule.id)}
          className="w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
        >
          開始營業
        </button>
      ) : currentStatus.status === 'open' ? (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-green-600 font-medium">營業中</span>
            <span className="text-sm text-gray-500">
              開始時間：{currentStatus.openTime}
            </span>
          </div>

          <div className="border-t pt-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              上傳營業照片
            </label>
            <div className="grid grid-cols-4 gap-2">
              {currentStatus.photos.map((photo, index) => (
                <div key={index} className="relative">
                  <img
                    src={photo}
                    alt={`營業照片 ${index + 1}`}
                    className="w-full h-24 object-cover rounded"
                  />
                  <button
                    onClick={() => {
                      // 實作刪除照片功能
                    }}
                    className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <label className="w-full h-24 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded hover:border-orange-500 cursor-pointer">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  className="hidden"
                  multiple
                />
                <Camera className="w-6 h-6 text-gray-400" />
                <span className="mt-2 text-sm text-gray-500">上傳照片</span>
              </label>
            </div>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={() => endOperation(schedule.id)}
              className="flex-1 px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
            >
              結束營業
            </button>
            <button
              onClick={() => setShowReportForm(true)}
              className="px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700"
            >
              <AlertTriangle className="w-5 h-5" />
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center py-4">
          <span className="text-gray-500">今日已結束營業</span>
        </div>
      )}

      {showReportForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">回報問題</h3>
              <button onClick={() => setShowReportForm(false)}>
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  問題描述
                </label>
                <textarea
                  value={reportContent}
                  onChange={(e) => setReportContent(e.target.value)}
                  rows={4}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                  placeholder="請描述遇到的問題..."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  上傳照片
                </label>
                <div className="grid grid-cols-4 gap-2">
                  {reportPhotos.map((photo, index) => (
                    <div key={index} className="relative">
                      <img
                        src={photo}
                        alt={`問題照片 ${index + 1}`}
                        className="w-full h-24 object-cover rounded"
                      />
                      <button
                        onClick={() => {
                          setReportPhotos(reportPhotos.filter((_, i) => i !== index));
                        }}
                        className="absolute top-1 right-1 bg-red-600 text-white rounded-full p-1"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                  <label className="w-full h-24 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded hover:border-orange-500 cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleReportPhotoUpload}
                      className="hidden"
                      multiple
                    />
                    <Upload className="w-6 h-6 text-gray-400" />
                    <span className="mt-2 text-sm text-gray-500">上傳照片</span>
                  </label>
                </div>
              </div>

              <div className="flex justify-end space-x-2 pt-4">
                <button
                  onClick={() => setShowReportForm(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  取消
                </button>
                <button
                  onClick={handleSubmitReport}
                  className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700"
                >
                  提交
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationStatus;