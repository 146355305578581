import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "./components/ErrorBoundary";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import TruckDetail from "./components/TruckDetail";
import VendorDashboard from "./pages/VendorDashboard";
import VenueDashboard from "./pages/VenueDashboard";
import CustomerDashboard from "./pages/CustomerDashboard";
import AdminDashboard from "./pages/AdminDashboard";
import AdminLoginPage from "./pages/AdminLoginPage";
import PrivateRoute from "./components/auth/PrivateRoute";
import AdminRoute from "./components/auth/AdminRoute";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthStore from "./stores/authStore";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, token } = useAuthStore();

  useEffect(() => {
    if (!user || !token) {
      navigate("/login", { state: { from: location.pathname } });
    }
  }, [user, token, navigate, location]);

  return user && token ? <>{children}</> : null;
};

const App = () => {

  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Routes>
            {/* 公開頁面 */}
            <Route
              path="/"
              element={
                <>
                  <Navbar />
                  <HomePage />
                </>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/truck/:id"
              element={
                <>
                  <Navbar />
                  <TruckDetail />
                </>
              }
            />
            <Route path="/admin/login" element={<AdminLoginPage />} />

            {/* 後台頁面 - 使用 PrivateRoute 保護 */}
            <Route
              path="/vendor/*"
              element={
                <PrivateRoute allowedRoles={["vendor"]}>
                  <VendorDashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/venue/*"
              element={
                <PrivateRoute allowedRoles={["venue"]}>
                  <VenueDashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/customer/*"
              element={
                <PrivateRoute allowedRoles={["customer"]}>
                  <CustomerDashboard />
                </PrivateRoute>
              }
            />

            {/* 管理員後台 */}
            <Route
              path="/admin/*"
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            />
          </Routes>
          <ToastContainer />
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
