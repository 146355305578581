import { create } from "zustand";
import { apiClient } from "../utils/api";
import { useAuthStore } from "./authStore";

export interface SpotStyle {
  backgroundColor: string;
  borderColor: string;
  label: string;
}

export interface Spot {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
  styleId: string;
  rotation: number;
  notes?: string;
  venueId: string;
  customLabel:string;
}

interface LayoutState {
  spots: Spot[];
  selectedSpotId: string | null;
  styles: Record<string, SpotStyle>;
  snapToGrid: boolean;
  gridSize: number;
  getSpotsByVenueId: (venueId: string) => Spot[];
  addSpot: (spot: Spot) => void;
  updateSpot: (id: string, updates: Partial<Spot>) => void;
  removeSpot: (id: string) => void;
  saveLayout: (venueId: string) => void;
  setSelectedSpot: (id: string) => void;
  setSnapToGrid: (checked: boolean) => void;
  fetchSpots: (venueId: string) => void;
}

const useLayoutStore = create<LayoutState>((set, get) => ({
  spots: [],
  selectedSpotId: null,
  styles: {
    default: {
      backgroundColor: "#FFF3E0",
      borderColor: "#FB923C",
      label: "標準攤位",
    },
    custom: {
      backgroundColor: "#E0F2F1",
      borderColor: "#4DB6AC",
      label: "萬用標示",
    },
  },
  snapToGrid: true,
  gridSize: 20,

  // 根據場地 ID 獲取攤位
  getSpotsByVenueId: (venueId: string) => {
    return get().spots.filter((spot) => spot.venueId === venueId);
  },

  // 添加攤位時包含場地 ID
  addSpot: (spot: Spot) => {
    set((state) => ({
      spots: [...state.spots, spot],
    }));
  },

  // 更新攤位
  updateSpot: (id: string, updates: Partial<Spot>) => {
    set((state) => ({
      spots: state.spots.map((spot) =>
        spot.id === id ? { ...spot, ...updates } : spot
      ),
    }));
  },

  // 移除攤位
  removeSpot: (id: string) => {
    set((state) => ({
      spots: state.spots.filter((spot) => spot.id !== id),
    }));
  },

  // 儲存場地配置
  saveLayout: async (venueId: string) => {
    const spots = get().getSpotsByVenueId(venueId);
    try {
      console.log("spots", spots);
      const token = useAuthStore.getState().token;
      // const response = await fetch(`/api/venue/savelayout`, {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${localStorage.getItem('token')}`
      //   },
      //   body: JSON.stringify({ spots })
      // });
      const response = await apiClient.post(`/venue/savelayout`, spots, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response);
      set((_state) => ({
        spots: response.data,
      }));
      // if (!response.ok) {
      //   throw new Error('儲存配置失敗');
      // }
    } catch (error) {
      console.error("儲存配置錯誤:", error);
      throw error;
    }
  },
  setSelectedSpot: async (id: string) => {
    set((_state) => ({
      selectedSpotId: id,
    }));
  },
  setSnapToGrid: async (checked: boolean) => {
    set((_state) => ({
      snapToGrid: checked,
    }));
  },
  fetchSpots: async (venueId: string) => {
    try {
      const token = useAuthStore.getState().token;
      if (!token) {
        throw new Error("請先登入");
      }

      const response = await apiClient.get(
        `/venue/getSpotsByVenue/${venueId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data", response.data);
      set({
        spots: response.data,
      });
    } catch (error: any) {
      throw error;
    }
  },
}));

export default useLayoutStore;
